<script setup>
import IMask from 'imask';
import dayjs from "dayjs";
import {computed, nextTick, onMounted, ref} from "vue";

const props = defineProps([
    'modelValue',
    'elementId',
]);

const emits = defineEmits(['update:modelValue', 'changed']);

const input = ref(null);

const value = computed({
    get() {
        return dayjs(props.modelValue).format('MM/DD/YYYY');
    },
    set(value) {
        return value;
    },
});

const dayjsMask = ref(null);

onMounted(() => {
    if (!props.elementId) {
        throw new Error('Element ID is required.');
    }

    nextTick(() => {
        let dobInput = document.getElementById(props.elementId);

        let dayjsFormat = 'MM/DD/YYYY';
        dayjsMask.value = IMask(dobInput, {
            mask: Date,
            pattern: dayjsFormat,
            lazy: false,
            min: new Date(1900, 0, 1),
            max: new Date(2900, 0, 1),

            format: function (date) {
                return dayjs(date).format(dayjsFormat);
            },
            parse: function (str) {
                return dayjs(str, dayjsFormat);
            },

            blocks: {
                YYYY: {
                    mask: IMask.MaskedRange,
                    from: 1900,
                    to: 2900
                },
                MM: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 12
                },
                DD: {
                    mask: IMask.MaskedRange,
                    from: 1,
                    to: 31
                },
            },

            autofix: true
        });
    })
})

function handleChange(e) {
    let targetValue = e.target.value;
    if (e.target.value === '__/__/____') {
      targetValue = null;
    }
    if (targetValue !== null && !dayjs(targetValue, 'MM/DD/YYYY', true).isValid()) {
      return;
    }
    emits('update:modelValue', targetValue);
    emits('changed');
    dayjsMask.value.updateValue();
}

</script>

<template>
    <input
        :id="elementId"
        ref="input"
        v-model="value"
        class="input-field"
        placeholder="MM/DD/YYYY"
        type="text"
        @blur="handleChange"
        @keydown.enter="handleChange"

    />
</template>

<style scoped>

</style>
