<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-10" @close="hideModal">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class=" relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-xl sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Create Text Template</DialogTitle>
                  <div>
                    <TextTemplateForm :type="type" :prepended_text="prepended_text" :appended_text="appended_text" :form-data="formData" :errors="errors" :loading="loading" @submit="createTextTemplate"></TextTemplateForm>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import TextTemplateForm from "@/Components/partials/TextTemplateForm.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import axios from "axios";
import helpers from "../../helper";
import serverValidationErrorsMixin from "../mixins/serverValidationErrorsMixin";

export default {
  name: "ActionScheduleCreateTextTemplateModal",

  mixins: [serverValidationErrorsMixin],

  components: {
    TextTemplateForm,
    LoadingSpinner,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },


  props: {
    user_is_admin: {
      type: Boolean,
      required: true,
    },
    open: {
      type: Boolean,
      default: false
    },
    prepended_text: {
      type: String,
      default: '',
    },
    appended_text: {
      type: String,
      default: '',
    },
    type: {
      type: Boolean,
      default: false,
    }
  },

  emits: ['text-template-created', 'modal-hidden'],

  data() {
    return {
      modalName: 'createTextTemplateModal',
      loading: false,
      formData: {
        name: null,
        message: null,
        type: 'Agent',
        location: 'B',
      }
    }
  },

  methods: {
    createTextTemplate() {
      if (this.loading) return;

      this.loading = true;

      setTimeout(() => {
        axios
          .post(route('text-templates.store'), this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response)
          })
          .catch((error) => {
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          })
      }, 500)
    },

    handleSuccessfulSubmission(response) {
      this.$emit('text-template-created', { template: response.data.template });
      this.formData = {
        name: null,
        message: null,
        type: 'Agent',
        location: 'B',
      };
      this.errors = {};
      this.hideModal();
    },

    handleErroredSubmission(error) {
      console.log(error);
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("ActionScheduleCreateTextTemplateModal - handleErroredSubmission", error.response);
    },

    hideModal() {
      this.$emit('modal-hidden', this.modalName)
    },
  },
}
</script>

<style scoped>

</style>
