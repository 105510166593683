<template>
  <form @submit.prevent="submit" class="col-span-12 box bg-white shadow p-4 rounded-lg" method="POST">
    <div>
<!--      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">-->
<!--        &lt;!&ndash; BEGIN: Billing Profile &ndash;&gt;-->
<!--        <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('billing_profile_id')}">-->
<!--          <label for="state_id" class=" text-sm text-gray-500">Billing Profile</label>-->
<!--          <select v-model="formData.billing_profile_id" data-placeholder="Select billing profile" class="input-field w-full" id="billing_profile_id" name="profile">-->
<!--            <option value="" selected>—</option>-->
<!--            <option value="agent-pays">Agent Pays</option>-->
<!--            <option v-for="(card, i) in billingProfiles" :key="card.id" :value="card.id">{{ `${card.full_name} Card ending in ${card.last_four_card_digits}` }}</option>-->
<!--          </select>-->
<!--          <div v-if="isInvalid('billing_profile_id')" class="error-message mt-1">{{ errorMessage('billing_profile_id') }}</div>-->
<!--          <br>-->
<!--          <button @click="showCreateBillingModal = true" type="button" data-tw-toggle="modal" data-tw-target="#create-billing-profile" class="text-sm mt-2 btn btn-primary btn-sm">Create new billing profile</button>-->
<!--        </div>-->
<!--        &lt;!&ndash; END: Billing Profile &ndash;&gt;-->
<!--      </div>-->
    </div>

   <div>
     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3  border-b border-slate-200 pb-6">
       <!-- BEGIN: recruiting_lead_id -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('recruiting_lead_id')}">
         <label for="recruiting_lead_id" class=" text-sm text-gray-500">TODO: Recruiting Lead</label>
         <select v-model="formData.recruiting_lead_id" data-placeholder="Select billing profile" class="input-field w-full" id="profile" name="profile">
           <option v-for="(lead, i) in recruitingLeads" :key="lead.id">{{ `${lead.first_name} ${lead.last_name}` }}</option>
         </select>
         <div v-if="isInvalid('recruiting_lead_id')" class="error-message mt-1">{{ errorMessage('recruiting_lead_id') }}</div>
       </div>
       <!-- END: recruiting_lead_id -->

       <!-- BEGIN: Leader -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('leader_ids')}">
         <label for="leader_ids" class=" text-sm text-gray-500">Direct Upline</label>
         <el-select v-model="formData.leader_ids" id="leader_ids" multiple class="w-full ml-2" clearable filterable>
           <el-option v-for="(leader, i) in possibleLeaders" :key="leader.id" :label="`${leader.first_name} ${leader.last_name}`" :value="leader.id" />
         </el-select>
         <div v-if="isInvalid('leader_ids')" class="error-message mt-1">{{ errorMessage('leader_ids') }}</div>
       </div>
       <!-- END: Leader -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
       <!-- BEGIN: First name -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('first_name')}">
         <label for="first_name" class=" text-sm text-gray-500">First name</label>
         <input v-model="formData.first_name" id="first_name" type="text" class="input-field w-full" name="first_name">
         <div v-if="isInvalid('first_name')" class="error-message mt-1">{{ errorMessage('first_name') }}</div>
       </div>
       <!-- END: First name -->

       <!-- BEGIN: Last name -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('last_name')}">
         <label for="last_name" class=" text-sm text-gray-500">Last name</label>
         <input v-model="formData.last_name" id="last_name" type="text" class="input-field w-full" name="last_name">
         <div v-if="isInvalid('last_name')" class="error-message mt-1">{{ errorMessage('last_name') }}</div>
       </div>
       <!-- END: Last name -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-200 pb-6">
       <!-- BEGIN: Title -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('title')}">
         <label for="title" class=" text-sm text-gray-500">Title</label>
         <input v-model="formData.title" id="title" type="text" class="input-field w-full" name="title">
         <div v-if="isInvalid('title')" class="error-message mt-1">{{ errorMessage('title') }}</div>
       </div>
       <!-- END: Title -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
       <!-- BEGIN: Address 1 -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('address')}">
         <label for="address" class=" text-sm text-gray-500">Address</label>
         <input v-model="formData.address" id="address" type="text" class="input-field w-full" name="address">
         <div v-if="isInvalid('address')" class="error-message mt-1">{{ errorMessage('address') }}</div>
       </div>
       <!-- END: Address 1 -->

       <!-- BEGIN: City -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('city')}">
         <label for="city" class=" text-sm text-gray-500">City</label>
         <input v-model="formData.city" id="city" type="text" class="input-field w-full" name="city">
         <div v-if="isInvalid('city')" class="error-message mt-1">{{ errorMessage('city') }}</div>
       </div>
       <!-- END: City -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-200 pb-6">
       <!-- BEGIN: State Id -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('state_id')}">
         <label for="state_id" class=" text-sm text-gray-500">State</label>
         <select v-model="formData.state_id" data-placeholder="Select state" class="input-field w-full" id="state_id" name="state_id">
           <option v-for="(state, i) in states" :key="state.id" :value="state.id">{{ state.name }}</option>
         </select>
         <div v-if="isInvalid('state_id')" class="error-message mt-1">{{ errorMessage('state_id') }}</div>
       </div>
       <!-- END: State Id -->

       <!-- BEGIN: Zip -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('zip')}">
         <label for="zip" class=" text-sm text-gray-500">Zip</label>
         <input v-maska="'######'" v-model="formData.zip" id="zip" type="text" name="zip" class="input-field w-full">
         <div v-if="isInvalid('zip')" class="error-message mt-1">{{ errorMessage('zip') }}</div>
       </div>
       <!-- END: Zip -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
       <!-- BEGIN: Email -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('email')}">
         <label for="email" class=" text-sm text-gray-500">Email</label>
         <input v-model="formData.email" id="email" type="email" name="email" class="input-field w-full">
         <div v-if="isInvalid('email')" class="error-message mt-1">{{ errorMessage('email') }}</div>
       </div>
       <!-- END: Email -->

       <!-- BEGIN: Password -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('password')}">
         <label for="password" class=" text-sm text-gray-500">Password</label>
         <input v-model="formData.password" id="password" type="password" name="password" class="input-field w-full">
         <div v-if="isInvalid('password')" class="error-message mt-1">{{ errorMessage('password') }}</div>
       </div>
       <!-- END: Password -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3  border-b border-slate-200 pb-6">
       <!-- BEGIN: Office Phone -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('office_phone')}">
         <label for="office_phone" class=" text-sm text-gray-500">Office Phone</label>
         <input v-maska="'(###) ###-####'" v-model="formData.office_phone" id="mobile_phone" type="text" name="mobile_phone" class="input-field w-full">
         <div v-if="isInvalid('office_phone')" class="error-message mt-1">{{ errorMessage('office_phone') }}</div>
       </div>
       <!-- END: Office Phone -->

       <!-- BEGIN: Mobile Phone -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('mobile_phone')}">
         <label for="mobile_phone" class=" text-sm text-gray-500">Mobile Phone</label>
         <input v-maska="'(###) ###-####'" v-model="formData.mobile_phone" id="mobile_phone" type="text" name="mobile_phone" class="input-field w-full">
         <div v-if="isInvalid('mobile_phone')" class="error-message mt-1">{{ errorMessage('mobile_phone') }}</div>
       </div>
       <!-- END: Mobile Phone -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
       <!-- BEGIN: Area Code -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('area_code')}">
         <label for="area_code" class=" text-sm text-gray-500">Area Code</label>
         <input maxlength="3" v-model="formData.area_code" id="area_code" type="text" name="area_code" class="input-field w-full">
         <div v-if="isInvalid('area_code')" class="error-message mt-1">{{ errorMessage('area_code') }}</div>
       </div>
       <!-- END: Area Code -->

       <!-- BEGIN: SMS Number -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('sms_number')}">
         <label for="sms_number" class=" text-sm text-gray-500">SMS Number</label>
         <select v-model="formData.sms_number" data-placeholder="Select source" class="input-field w-full" id="sms_number" name="sms_number">
           <option value="assign-new" selected>Assign New Number</option>
           <option :value="twilioPhoneNumber.phone_number" v-for="twilioPhoneNumber in availableTwilioPhoneNumbers">
             {{ twilioPhoneNumber.phone_number }}
           </option>
         </select>
         <div v-if="isInvalid('sms_number')" class="error-message mt-1">{{ errorMessage('sms_number') }}</div>
       </div>
       <!-- END: SMS Number -->
     </div>

     <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
       <!-- BEGIN: Timezone -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('time_zone')}">
         <label for="time_zone" class=" text-sm text-gray-500">Time zone</label>
         <select v-model="formData.time_zone" data-placeholder="Select source" class="input-field w-full" id="timezone" name="timezone">
           <option v-for="(time_zone, i) in timezones" :key="time_zone.value" :value="time_zone.value">{{ time_zone.label }}</option>
         </select>
         <div v-if="isInvalid('time_zone')" class="error-message mt-1">{{ errorMessage('time_zone') }}</div>
       </div>
       <!-- END: Timezone -->

       <!-- BEGIN: Round Robin Groups -->
       <div class="col-span-12 lg:col-span-6 " :class="{'has-error': isInvalid('round_robin_groups')}">
         <label for="round_robin_groups" class=" text-sm text-gray-500">TODO: Round Robin Groups (Sample data)</label>
         <select v-model="formData.round_robin_groups" data-placeholder="Select source" class="input-field w-full" multiple id="round_robin_groups" name="round_robin_groups[]">
           <option value="1" selected>BDC Appointment Test</option>
           <option value="2" selected>Delay Test</option>
           <option value="3" selected>Premier</option>
           <option value="4" selected>Test Fourth Group</option>
           <option value="5" selected>Test Group!</option>
           <option value="6" selected>Test Second Group</option>
           <option value="7" selected>Test Third Group</option>
         </select>
         <div v-if="isInvalid('round_robin_groups')" class="error-message mt-1">{{ errorMessage('round_robin_groups') }}</div>
       </div>
       <!-- END: Round Robin Groups -->
     </div>

     <div class="text-right mt-5">
       <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
          <LoadingSpinner v-if="loading"></LoadingSpinner>
         <span v-else>Submit</span>
       </button>
     </div>
   </div>
  </form>

  <!-- Create Billing Profile Modal  -->
  <TransitionRoot as="template" :show="showCreateBillingModal">
    <Dialog as="div" class="relative z-10" @close="showCreateBillingModal = false">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100"
                       leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
      </TransitionChild>

      <div class="fixed z-10 inset-0 overflow-y-auto">
        <div class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300"
                           enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                           enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200"
                           leave-from="opacity-100 translate-y-0 sm:scale-100"
                           leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel
              class="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
              <div>
                <div class="mt-3 sm:mt-5">
                  <DialogTitle as="h3" class="text-lg leading-6 font-medium text-gray-900 text-center">Create Billing Profile</DialogTitle>
                  <div>
                    <app-billing-profile-create-form :agency-id="agencyId" :states="states" :months="months" :years="years" @billing-profile-added="handleBillingProfileAdded"></app-billing-profile-create-form>
                  </div>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
  <!-- End Create Billing Profile Modal -->
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot} from "@headlessui/vue";
import serverValidationErrorsMixin from "./mixins/serverValidationErrorsMixin";
import AppBillingProfileCreateForm from "./AppBillingProfileCreateForm.vue";
import helpers from "../helper";
import {ElOption, ElSelect} from "element-plus";

export default {
  name: "AppAgentCreate",

  mixins: [serverValidationErrorsMixin],

  components: {
    ElSelect, ElOption,
    LoadingSpinner,
    AppBillingProfileCreateForm,
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
  },

  props: {
    agencyId: {
      type: Number,
      required: true
    },
    states: {
      type: Array,
      default: () => [],
    },
    months: {
      type: Array,
      default: () => []
    },
    years: {
      type: Array,
      default: () => []
    },
    timezones: {
      type: Array,
      default: () => []
    },
    possibleLeaders: {
      type: Array,
      default: () => []
    },
    availableTwilioPhoneNumbers: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      formData: {
        billing_profile_id: null,
        recruiting_lead_id: null,
        leader_id: null,
        leader_ids: [],
        first_name: null,
        last_name: null,
        title: null,
        address: null,
        city: null,
        state_id: null,
        zip: null,
        email: null,
        password: null,
        office_phone: null,
        mobile_phone: null,
        area_code: null,
        sms_number: 'assign-new',
        time_zone: null,
        round_robin_groups: [],
      },
      loading: false,
      billingProfiles: [],
      recruitingLeads: [
        {id: 1, first_name: 'Dummy', last_name: 'Data1'},
        {id: 2, first_name: 'Dummy', last_name: 'Data2'},
        {id: 3, first_name: 'Dummy', last_name: 'Data3'}
      ],
      showCreateBillingModal: false
    }
  },

  computed: {
    billingProfileId() {
      return this.formData.billing_profile_id;
    },
    shouldShowFormFields() {
      return ![null, ""].includes(this.billingProfileId);
    },
  },

  watch: {
    billingProfileId(newValue, oldValue) {
      if (newValue === 'agent-pays') {
        Swal.fire({
          text: 'You will be prompted to enter your credit card information before accessing Top Broker.',
          icon: 'info'
        })
      }
    }
  },

  mounted() {
    this.initializeComponent();
  },

  methods: {
    initializeComponent() {
      const vm = this;
      const createBillingProfile = document.getElementById('create-billing-profile')
      createBillingProfile?.addEventListener('hidden.tw.modal', function (event) {
        // vm.cleanUp();
        vm.loading = false;
      });

      this.fetchBillingProfiles();
    },

    fetchBillingProfiles() {
      this.loading = true;
        axios
          .get(`/agencies/${this.agencyId}/billing-profiles`)
          .then((response) => {
            this.billingProfiles = response.data;
          })
          .catch((error) => {
            console.log(error, 'AppAgentCreateForm - fetchBillingProfiles()')
          })
          .finally(() => {
            this.loading = false;
          });
    },

    submit(e) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        axios
          .post('/agents', this.formData)
          .then((response) => {
            this.handleSuccessfulSubmission(response);
          })
          .catch((error) => {
            console.log(error, 'AppAgentCreateForm - submit()')
            this.handleErroredSubmission(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }, 1000)
    },

    handleBillingProfileAdded(payload) {
      this.billingProfiles.push(payload);
      this.showCreateBillingModal = false;
    },

    handleSuccessfulSubmission(response) {
      setTimeout(() => {
        if (response.data && response.data.redirect_url) {
          this.$inertia.get(response.data.redirect_url);
        } else {
          this.$inertia.get('/agents');
        }
      }, 200);
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("AppLeadCreateForm - handleErroredSubmission", error.response);
    },

    cleanUp() {
      this.errors = {};
      this.formData = {
        billing_profile_id: null,
        recruiting_lead_id: null,
        leader_id: null,
        leader_ids: [],
        first_name: null,
        last_name: null,
        title: null,
        address: null,
        city: null,
        state_id: null,
        zip: null,
        email: null,
        password: null,
        office_phone: null,
        mobile_phone: null,
        area_code: null,
        sms_number: 'assign-new',
        timezone: null,
        round_robin_groups: []
      };
    }
  },
}
</script>

<style scoped></style>