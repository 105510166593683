<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {computed, onMounted} from "vue";
import EmojiPicker from "@/Components/EmojiPicker.vue";
import {useMergeFieldDragAndDropHandler, useInitDragAndDropFields} from "@/utils/useMergeFieldDragAndDropHandler";

const props = defineProps({
  type: Boolean,
  formData: Object,
  loading: Boolean,
  prepended_text: String,
  appended_text: String,
  errors: {
    type: Object,
    default: null
  }
})

const emit = defineEmits(['submit']);

const previewMessage = computed(() => {
  return `${props.prepended_text} ${props.formData?.message ?? '[MESSAGE]'} ${props.appended_text}`;
})

function isInvalid(attribute) {
  if (props.formData.errors && props.formData.errors[attribute]) {
    return true;
  } else {
    return props.errors && props.errors.hasOwnProperty(attribute);
  }
}

function errorMessage(attribute) {
  if (props.formData.errors && props.formData.errors[attribute]) {
    return props.formData.errors[attribute];
  } else if (props.errors && props.errors.hasOwnProperty(attribute)) {
    return props.errors[attribute][0];
  }
}

onMounted(() => {
    useInitDragAndDropFields();
})
</script>

<template>
  <form @submit.prevent="emit('submit')" class="intro-y box bg-white p-5">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Type -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('type') }">
        <label for="type" class="form-label text-gray-600">Type</label>
        <select v-model="formData.type" class="input-field" name="" id="">
          <option v-if="type" value="Agency">Agency</option>
          <option value="Agent">Agent</option>
        </select>
        <div class="error-message mt-1" v-if="isInvalid('type')">{{ errorMessage('type') }}</div>
      </div>
      <!-- END: Type -->

      <!-- BEGIN: Name -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('name')}">
        <label for="name" class="form-label text-gray-600">Template Name</label>
        <input v-model="formData.name" id="name" type="text" class="input-field"
               name="name">
        <div class="error-message mt-1" v-if="isInvalid('name')">{{ errorMessage('name') }}</div>
      </div>
      <!-- END: Name -->

      <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3 border-b border-slate-100 pb-6 col-span-12">
        <!-- BEGIN: Text Message -->
        <div class="col-span-12 lg:col-span-6 input-form" :class="{'has-error': isInvalid('message')}">
          <label for="text-template-message-form-field" class="form-label text-s text-slate-600">Message</label>
            <div class="relative flex">
                <span class="absolute flex items-center bg-gray-100 rounded-full m-1">
                <emoji-picker input-element-id="text-template-message-form-field" :text="formData.message" @emoji-selected="(e) => formData.message = e.updatedText"></emoji-picker>
            </span>
                <textarea @dragover.prevent @drop.prevent="props.formData.message = useMergeFieldDragAndDropHandler($event)" contenteditable="true" tabindex="0" v-model="formData.message" name="message" id="text-template-message-form-field" maxlength="6000" rows="6" cols="50" type="text" class="input-field w-full pl-14"></textarea>
            </div>
          <div class="error-message mt-1" v-if="isInvalid('message')">{{ errorMessage('message') }}</div>
        </div>
        <!-- END: Text Message -->
        <!-- BEGIN: Text Message -->
        <div class="col-span-12 lg:col-span-6 input-form">
          <label for="message" class="form-label text-s text-slate-500">Preview</label>
          <textarea  name="message" id="preview" rows="6" cols="50" type="text" class="input-field w-full" disabled>{{ previewMessage }}</textarea>
          <span class="text-xs">The SenderID and Opt Out appears on the first message that is sent out – example:
            {{prepended_text}}You had inquired about health insurance. How can I help?{{appended_text}}
          </span>
        </div>
        <!-- END: Text Message -->
      </div>

      <!-- BEGIN: Location -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('location')}">
        <label for="location" class="form-label text-gray-600">Location</label>
        <select v-model="formData.location" class="input-field" name="" id="">
          <option value="B">Both</option>
          <option value="A">Action Wizard</option>
          <option value="T">Toolbelt</option>
        </select>
        <div class="error-message mt-1" v-if="isInvalid('location')">{{ errorMessage('location') }}</div>
      </div>
      <!-- END: Location -->

      <!-- BEGIN: Merge fields -->
      <div class="col-span-12 input-form">
        <label for="tooltip" class="form-label text-s text-gray-600 mb-2">Available Merge Fields</label>
        <p class="text-sm text-gray-600 box-border rounded-lg h-35 w-45 p-2 shadow border border-gray-300">
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[firstname]</span> - The Lead's First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[lastname]</span> - The Lead's Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[state]</span> - The Lead's State<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[zip]</span> - The Lead's Zip Code<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentfirst]</span> - The Agent's First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentlast]</span> - The Agent's Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentphone]</span> - The Agent's Phone Number<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[calendar_sharing_link]</span> - The Agent's Calendar Sharing Link<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[leaddob]</span> - The Lead's Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spousefirstname]</span> - The Lead's Spouse First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spouselastname]</span> - The Lead's Spouse Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spousedob]</span> - The Lead's Spouse Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob1]</span> - The Lead's First Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob2]</span> - The Lead's Second Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob3]</span> - The Lead's Third Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob4]</span> - The Lead's Fourth Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob5]</span> - The Lead's Fifth Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactionstamp]</span> - Date/Time of Next Action<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactiondate]</span> - Date of Next Action<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactiontime]</span> - Time of Next Action<br>
        </p>
      </div>
      <!-- END: Merge fields -->
    </div>

    <div class="text-right mt-5">
      <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>
