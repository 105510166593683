<template>
  <form @submit.prevent="submit" class="intro-y box p-5 col-span-12 bg-white" method="POST">
    <div class="grid grid-cols-12 gap-3 lg:gap-6 mt-3">
      <!-- BEGIN: Name -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('name')}">
        <label for="name" class="form-label text-gray-800">Name</label>
        <input :readonly="!editable" v-model="formData.name" id="name" type="text" class="input-field w-full" name="name">
        <div v-if="isInvalid('name')" class="error-message mt-1">{{ errorMessage('name') }}</div>
      </div>
      <!-- END: Name -->

      <!-- BEGIN: Script Text -->
      <div class="col-span-12 input-form" :class="{'has-error': isInvalid('text')}">
        <label for="text" class="form-label text-gray-800">Script Text</label>
        <textarea :readonly="!editable" @dragover.prevent @drop="handleDrop" v-model="formData.text" id="voicemail-script-text" type="text" class="ckeditor input-field w-full" name="text" rows="8"></textarea>
        <div v-if="isInvalid('text')" class="error-message mt-1">{{ errorMessage('text') }}</div>
      </div>
      <!-- END: Script Text -->

      <div v-if="$page.props.auth.is_super_admin || $page.props.auth.is_agency_admin" class="flex items-center justify-start space-x-2 w-full col-span-12 input-form">
        <input :readonly="!editable" type="checkbox" class="input-checkbox block" :checked="formData.is_agency" v-model="formData.is_agency" name="is_this_agency_voicemail_script">
        <p class="font-semibold">Is this agency voicemail script?</p>
      </div>

      <!-- BEGIN: Merge fields -->
      <div :class="[{'pointer-events-none': !editable}, 'col-span-12 input-form']">
        <label for="tooltip" class="form-label text-s text-gray-800 mb-2">Available Merge Fields</label>
        <p class="text-sm text-gray-800 box-border rounded-lg h-35 w-45 p-2 shadow border border-gray-300">
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[firstname]</span> - The Lead's First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[lastname]</span> - The Lead's Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[state]</span> - The Lead's State<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[zip]</span> - The Lead's Zip Code<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentfirst]</span> - The Agent's First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentlast]</span> - The Agent's Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[agentphone]</span> - The Agent's Phone Number<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[leaddob]</span> - The Lead's Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spousefirstname]</span> - The Lead's Spouse First Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spouselastname]</span> - The Lead's Spouse Last Name<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spousedob]</span> - The Lead's Spouse Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[spousedob]</span> - The Lead's Spouse Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob1]</span> - The Lead's First Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob2]</span> - The Lead's Second Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob3]</span> - The Lead's Third Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob4]</span> - The Lead's Fourth Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[dependentdob5]</span> - The Lead's Fifth Dependent Date of Birth<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactionstamp]</span> - Date/Time of Next Action<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactiondate]</span> - Date of Next Action<br>
            <span draggable="true" class="cursor-move draggable inline-block btn-secondary text-sm py-1 px-2 mb-1">[nextactiontime]</span> - Time of Next Action<br>
        </p>
      </div>
      <!-- END: Merge fields -->
    </div>


    <div v-if="editable" class="text-right mt-5">
      <button type="submit" class="btn btn-primary w-24" :class="{'pointer-events-none': loading}">
        <LoadingSpinner v-if="loading"></LoadingSpinner>
        <span v-else>Submit</span>
      </button>
    </div>
  </form>
</template>

<script>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import _ from "lodash";
import serverValidationErrorsMixin from "./mixins/serverValidationErrorsMixin";
import helpers from "../helper";

export default {
  name: "AppVoicemailScriptCreateForm",
  components: {LoadingSpinner},
  mixins: [serverValidationErrorsMixin],

  props: {
    createMode: {
      type: Boolean,
      default: true,
    },

    shouldRedirect: {
      type: Boolean,
      default: true
    },

    scriptData: {
      type: Object,
      default: null,
    },

    modalId: {
      type: String,
      default: 'create-voicemail-script-modal'
    },

    editable: {
      type: Boolean,
      default: true,
    }
  },

  data() {
    return {
      formData: {
        name: null,
        text: null,
        is_agency: false,
      },
      loading: false,
    }
  },

  mounted() {
      const draggableElements = document.querySelectorAll('.draggable');

      draggableElements.forEach(el => {
          // Set the dataTransfer data for each el with .draggable class;
          el.addEventListener('dragstart', function(event) {
              event.dataTransfer.setData('text', el.textContent);
          });
      });


    this.initializeComponent();
  },

  methods: {
    initializeComponent() {
      if (this.scriptData && this.createMode === false) {
        this.formData = _.cloneDeep(this.scriptData);
      }

      if (this.createMode) {
      }
    },

    submit(e) {
      if (this.loading) return;
      this.loading = true;

      setTimeout(() => {
        if (this.createMode) {
          this.createVoicemailScript();
        } else {
          this.updateVoicemailScript();
        }
      }, 1000)
    },

    createVoicemailScript() {
      axios
        .post('/voicemail-scripts', this.formData)
        .then((response) => {
          this.handleSuccessfulSubmission(response);
        })
        .catch((error) => {
          console.log(error, 'AppVoicemailScriptCreateForm - createVoicemailScript()')
          this.loading = false;
          this.handleErroredSubmission(error);
        });
    },

    updateVoicemailScript() {
      axios
        .put(`/voicemail-scripts/${this.formData.id}`, this.formData)
        .then((response) => {
          this.handleSuccessfulSubmission(response);
        })
        .catch((error) => {
          console.log(error, 'AppVoicemailScriptCreateForm - updateVoicemailScript()')
          this.loading = false;
          this.handleErroredSubmission(error);
        });
    },

    handleSuccessfulSubmission(response) {
      if (this.createMode) {
        this.hideModal();
      }

      setTimeout(() => {
        if (this.shouldRedirect && response.data && response.data.redirect_url) {
          this.$inertia.get(response.data.redirect_url);
        } else {
          this.$emit('script-created', {script: response.data.script});
        }
      }, 200);
    },

    handleErroredSubmission(error) {
      if (error.response && error.response.data && error.response.status === 422) {
        this.errors = error.response.data.errors;
        this.$nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      } else {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });
      }
      console.log("AppVoicemailScriptCreateForm - handleErroredSubmission", error.response);
    },

    hideModal() {
      this.$emit('hide-modal');
    },

    cleanUp() {
      this.errors = {};
      this.formData = {
        name: null,
        text: null,
      };
    },

      handleDrop(e) {
          e.preventDefault();
          const targetField = e.target;
          const start = targetField.selectionStart;
          const end = targetField.selectionEnd;
          const value = targetField.value ?? '';
          const draggedText = e.dataTransfer.getData("text");

          // Insert the dragged text content at the current caret position
          targetField.value = value.substring(0, start) + draggedText + value.substring(end);
          targetField.selectionStart = start + draggedText.length;
          targetField.selectionEnd = start + draggedText.length;
          return this.formData.text = targetField.value;
      }
  },
}
</script>

<style scoped></style>
