<script setup>
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import { Head } from "@inertiajs/inertia-vue3";
import { ElSelect, ElOption } from 'element-plus';
import { ref, computed, watch } from "vue";
import { ElTooltip } from 'element-plus';
import { QuestionMarkCircleIcon, EyeIcon } from "@heroicons/vue/solid";
import MaskedCurrencyInput from "@/Components/MaskedCurrencyInput.vue";
import { range } from "lodash";
import MaskedDatePicker from "@/Components/MaskedDatePicker.vue";
import Modal from "@/Components/Modal.vue";
import BeneficiariesList from "@/Components/BeneficiariesList.vue";
import BeneficiariesForm from "@/Components/BeneficiariesForm.vue";
import moment from "moment/moment";

const props = defineProps({
  loading: Boolean,
  products: Object,
  enrollees: Array,
  leadId: {
    type: Number,
    required: true,
  },
  lead: Object,
  hst_trans_id: String,
  tempTransactions: Array,
  totalPremium: Number,
  agents: Array,
  splitPercentages: Array,
  formData: Object,
  policies: Object,
  carriersData: Object,
  applicants: Array,
  hst_available: Boolean,
  activeTab: String,
  policy: Object,
  leadSchedules: Array,
  user: Object,
})

const emit = defineEmits(['onSubmit', 'enrollees'])

const splitSaleOption = ref(false)

const groupedProductsBasedOnSelectedCarrier = computed(() => {
  return props.formData.selectedCarrier?.products;
})

const group = computed(() => {
  return props.formData.applicants?.id;
})

watch(() => props.formData.selectedCarrier, () => {
  props.formData.products = null;
  props.formData.enrollees = null;

});

const shouldShowEnrolleesField = computed(() => {
  const categoryId = props.products.find(i => i.id === props.formData.products)?.category_id;
  return ([9, 10].includes(categoryId));
});

const showFaceAmount = computed(() => {
    const product = props.products?.find(i => i.id === props.formData.products)?.category_id;
    return ([1, 5, 12].includes(product));
});

const showingListBeneficiariesModal = ref(false);
const showingFormBeneficiariesModal = ref(false);
watch(props.formData.beneficiaries, validateBeneficiariesPercentageSum);
const beneficiarySumError = ref(false);
function validateBeneficiariesPercentageSum(){
  props.formData.errors.beneficiaries = '';
  beneficiarySumError.value = false;
  let sum = props.formData.beneficiaries.reduce((tot, curr) => tot + parseInt(curr.split_percentage), 0);
  if (props.formData.beneficiaries.length && sum !== 100) {
    props.formData.errors.beneficiaries = 'Beneficiares percentage must add to 100%';
    beneficiarySumError.value = true;
  }
}
function processBeneficiary($event, addingBeneficiary) {
  const beneficiaryData = {
    name: addingBeneficiary.value.name,
    email: addingBeneficiary.value.email,
    phone: addingBeneficiary.value.phone,
    address: addingBeneficiary.value.address,
    split_percentage: addingBeneficiary.value.split_percentage,
  }
  if (typeof editingBeneficiary.value.index === 'number' && editingBeneficiary.value.index >= 0 && Object.keys(editingBeneficiary.value.data).length > 0) {
    // Modify
    props.formData.beneficiaries[editingBeneficiary.value.index] = beneficiaryData;
  } else {
    // Add
    props.formData.beneficiaries.push(beneficiaryData);
  }
  hideFormBeneficiariesModal();
}
function showListBeneficiariesModal() {
  showingListBeneficiariesModal.value = true;
}
function hideListBeneficiariesModal() {
  showingListBeneficiariesModal.value = false;
}
function showFormBeneficiariesModal() {
  showingFormBeneficiariesModal.value = true;
}
function hideFormBeneficiariesModal() {
  showingFormBeneficiariesModal.value = false;
  editingBeneficiary.value.index = null;
  editingBeneficiary.value.data = {};
}
function deleteBeneficiary(index) {
  props.formData.beneficiaries.splice(index, 1);
}
const editingBeneficiary = ref({
  index: null,
  data: {},
});
function editBeneficiary(index) {
  editingBeneficiary.value.index = index;
  editingBeneficiary.value.data = props.formData.beneficiaries[index];
  showFormBeneficiariesModal();
}
const canSubmitTransaction = computed(() => {
  return !beneficiarySumError.value;
});

/** BEGIN Completing Task/Schedule/Appt */
const submittingCompleteSchedules = ref(false);
const showLeadScheduleChoicesModal = ref(false);
watch(props.formData.selectedApptsAndTasksToComplete, (value) => {
  if (value.length) {
    props.formData.selectedScheduleToComplete = null;
  }
});
function hideLeadScheduleChoicesModal() {
  props.formData.selectedScheduleToComplete = null;
  props.formData.selectedApptsAndTasksToComplete = [];
  showLeadScheduleChoicesModal.value = false;
}
const leadSchedulesOwnedByUser = computed(() => {
  return props.leadSchedules?.filter(i => i.user_id === props.user.id);
});
const leadActionsThatFollowScheduleOwnedByUser = computed(() => {
  return leadSchedulesOwnedByUser.value?.filter(i => i.action.is_task !== true && i.action.id !== 1);
});
const leadAppointmentAndTasksOwnedByUser = computed(() => {
  return leadSchedulesOwnedByUser.value?.filter(i => i.action.is_task === true || i.action.id === 1);
});
function chooseComplete() {
  let shouldDisplayChoicesModal = false /*disabled*/ && props.leadSchedules.length > 0 && (props.formData.selectedScheduleToComplete == null && props.formData.selectedApptsAndTasksToComplete.length === 0);
  if (shouldDisplayChoicesModal) {
    showLeadScheduleChoicesModal.value = true;
  } else {
    emit('onSubmit');
  }
}
/** END Completing Task/Schedule/Appt */
</script>

<template>
  <Head title="Create Policy" />
  <form @submit.prevent="chooseComplete"
        class="intro-y box p-4">
    <!-- BEGIN: Carrier -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Carriers</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.products }">
        <select v-model="formData.selectedCarrier"
                data-placeholder="Select source"
                class="tom-select input-field ml-2 w-full"
                id="product"
                name="product">
          <option value="null">Select a carrier</option>
          <option v-for="carrier in carriersData.data"
                  :key="`carrier_${carrier.agency_carrier_id}`"
                  :value="carrier">{{ carrier.carrier_name }}</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.products">{{ formData.errors.products }}</div>
      </dd>
    </div>
    <!-- END: Carrier -->
    <!-- BEGIN: Product -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Products</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.products }">
        <select v-model="formData.products"
                class="tom-select input-field ml-2 w-full"
                id="product"
                name="product">
          <option :value="null">Select a product</option>
          <option v-for="product in groupedProductsBasedOnSelectedCarrier"
                  :key="`product_${product.id}`"
                  :value="product.id">{{ product.name }}</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.products">{{ formData.errors.products }}</div>
      </dd>
    </div>
    <!-- END: Product -->
    <!-- BEGIN: Enrollees -->
    <div v-if="shouldShowEnrolleesField"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Enrollees</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.enrollees }">
        <select v-model="formData.enrollees"
                data-placeholder="Select source"
                class="tom-select input-field ml-2 w-full"
                id="enrollees"
                name="enrollees">
          <option value="null">Select</option>
          <option v-for="enrollee in enrollees"
                  :key="enrollee.id"
                  :value="enrollee">{{ enrollee }}</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.enrollees">{{ formData.errors.enrollees }}</div>
      </dd>
    </div>
    <!-- END: Enrollees -->
    <!-- BEGIN: Applicants -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Applicants</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.applicants }">
        <el-select v-model="formData.applicants"
                   multiple
                   class="w-full ml-2"
                   clearable
                   filterable>
          <el-option v-for="(dependant, index) in applicants"
                     :key="dependant.id"
                     :value="dependant.id"
                     :label="`${dependant.first_name} ${dependant.last_name ?? ''}`" />
        </el-select>
        <div class="error-message mt-1"
             v-if="formData.errors.applicants">{{ formData.errors.applicants }}</div>
      </dd>
    </div>
    <!-- END: Applicants -->
    <!-- BEGIN: Premium -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm font-medium mt-1 text-gray-600">Total Premium</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.premium }">
        <masked-currency-input v-model="formData.premium"
                               id="premium"
                               class="input-field ml-2 w-full"></masked-currency-input>
        <div class="error-message mt-1"
             v-if="formData.errors.premium">{{ formData.errors.premium }}</div>
      </dd>
    </div>
    <!-- END: Premium -->
    <!-- BEGIN: Policy Number -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap mt-1 mr-2 font-medium text-gray-600">Policy Number 1</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.policy_number }">
        <input v-model="formData.policy_number"
               id="policy_number"
               type="text"
               name="policy_number"
               class=" input-field ml-2 w-full">
        <div class="error-message mt-1"
             v-if="formData.errors.policy_number">{{ formData.errors.policy_number }}</div>
      </dd>
    </div>
    <!-- END: Policy Number -->
    <!-- BEGIN: Effective Date -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap font-medium mt-1 text-gray-600">Effective Date 1</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.policy_number }">
        <masked-date-picker class="ml-2"
                            element-id="prefix-effective-date-1"
                            v-model="formData.effective_date"></masked-date-picker>
        <div class="error-message mt-1"
             v-if="formData.errors.effective_date">{{ formData.errors.effective_date }}</div>
      </dd>
    </div>
    <!-- END: Effective Date -->
    <!-- BEGIN: End Date -->
    <div v-if="products.find(i => i.id === formData.products)?.collect_end_date"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap font-medium mt-1 text-gray-600">End Date 1</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.end_date }">
        <masked-date-picker class="ml-2"
                            element-id="prefix-end-date-1"
                            v-model="formData.end_date"></masked-date-picker>
        <div class="error-message mt-1"
             v-if="formData.errors.end_date">{{ formData.errors.end_date }}</div>
      </dd>
    </div>
    <!-- END: End Date -->
    <!-- BEGIN: Draft Date -->
    <div
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap font-medium mt-1 text-gray-600">Draft Date</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.draft_date }">
        <masked-date-picker class="ml-2"
                            element-id="draft-date-1"
                            v-model="formData.draft_date"></masked-date-picker>
        <div class="error-message mt-1"
             v-if="formData.errors.draft_date">{{ formData.errors.draft_date }}</div>
      </dd>
    </div>
    <!-- END: Draft Date -->
    <!-- BEGIN: Face Amount -->
    <div v-if="showFaceAmount" class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm font-medium mt-1 text-gray-600">Face Amount</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.face_amount }">
        <masked-currency-input v-model="formData.face_amount"
                               id="face-amount"
                               class="input-field ml-2 w-full"></masked-currency-input>
        <div class="error-message mt-1"
             v-if="formData.errors.face_amount">{{ formData.errors.face_amount }}</div>
      </dd>
    </div>
    <!-- END: Face Amount -->

    <!-- BEGIN: Sub Transactions Array -->
    <div v-for="index in range(0, 5)">
      <div v-if="products.find(i => i.id === formData.products)?.policy_numbers - 1 > index">
        <!-- BEGIN: Name -->
        <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="block text-sm whitespace-nowrap mt-1 mr-2 font-medium text-gray-600">Policy Number {{ index + 2 }}
          </dt>
          <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              :class="{ 'has-error': formData.errors.sub_policies && formData.errors.sub_policies[index]?.policy_number }">
            <input v-model="formData.sub_policies[index].policy_number"
                   id="policy_number"
                   type="text"
                   name="policy_number"
                   class=" input-field ml-2 w-full">
            <div class="error-message mt-1"
                 v-if="formData.errors.sub_policies && formData.errors.sub_policies[index]?.policy_number">{{
                   formData.errors.sub_policies[index].policy_number }}</div>
          </dd>
        </div>
        <!-- END: Name -->
        <!-- BEGIN: Lead Date -->
        <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="block text-sm whitespace-nowrap font-medium mt-1 text-gray-600">Effective Date {{ index + 2 }}</dt>
          <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              :class="{ 'has-error': formData.errors.sub_policies && formData.errors.sub_policies[index]?.effective_date }">
            <masked-date-picker class="ml-2"
                                :element-id="`effective-date${index}`"
                                v-model="formData.sub_policies[index].effective_date"></masked-date-picker>
            <div class="error-message mt-1"
                 v-if="formData.errors.sub_policies && formData.errors.sub_policies[index]?.effective_date">{{
                   formData.errors.sub_policies[index].effective_date }}</div>
          </dd>
        </div>
        <!-- END: Lead Date -->
        <!-- BEGIN: Lead Date -->
        <div v-if="products.find(i => i.id === formData.products)?.collect_end_date"
             class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
          <dt class="block text-sm whitespace-nowrap font-medium mt-1 text-gray-600">End Date {{ index + 2 }}</dt>
          <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
              :class="{ 'has-error': formData.errors.sub_policies && formData.errors.sub_policies[index]?.end_date }">
            <masked-date-picker class="ml-2"
                                :element-id="`end-date${index}`"
                                v-model="formData.sub_policies[index].end_date"></masked-date-picker>
            <div class="error-message mt-1"
                 v-if="formData.errors.sub_policies && formData.errors.sub_policies[index]?.end_date">{{
                   formData.errors.sub_policies[index].end_date }}</div>
          </dd>
        </div>
      </div>
    </div>
    <!-- END: Sub Transactions Array -->

    <!-- BEGIN: Name -->
    <div v-if="products.find(i => i.id === formData.products)?.category_id == 14"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap mt-1 mr-2 font-medium text-gray-600">MarketPlace ID</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.taxcreditid }">
        <input v-model="formData.taxcreditid"
               id="taxcreditid"
               type="text"
               name="taxcreditid"
               class=" input-field ml-2 w-full">
        <div class="error-message mt-1"
             v-if="formData.errors.taxcreditid">{{ formData.errors.taxcreditid }}</div>
      </dd>
    </div>
    <!-- END: Name -->
    <!-- BEGIN: Name -->
    <div v-if="products.find(i => i.id === formData.products)?.category_id == 14"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap mt-1 mr-2 font-medium text-gray-600">Client Portion</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.client_portion }">
        <input v-model="formData.client_portion"
               id="client_portion"
               type="text"
               name="client_portion"
               class=" input-field ml-2 w-full">
        <div class="error-message mt-1"
             v-if="formData.errors.client_portion">{{ formData.errors.client_portion }}</div>
      </dd>
    </div>
    <!-- END: Name -->
    <!-- BEGIN: Type -->
    <div v-if="hst_available"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Business Type</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.business_type }">
        <select v-model="formData.business_type"
                data-placeholder="Select source"
                class="tom-select input-field ml-2 w-full"
                id="business_type"
                name="business_type">
          <option value="null">Select a type</option>
          <option value="N">New Business</option>
          <option value="R">Renewal Business</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.business_type">{{ formData.errors.business_type }}</div>
      </dd>
    </div>
    <!-- END: Type -->
    <!-- BEGIN: HST Transaction Id -->
    <div v-if="hst_trans_id"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">HST Trans Id</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <input v-model="hst_trans_id"
               id="policy_number"
               type="text"
               name="policy_number"
               disabled
               class=" input-field ml-2 w-full">
      </dd>
    </div>
    <!-- END: Type -->
    <!-- BEGIN: Show option -->
    <div v-if="products.find(i => i.id === formData.products)?.track_assisted_sales == 1"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm whitespace-nowrap mt-1 mr-2 font-medium text-gray-600">Show split sale options?</dt>
      <dd class="ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
        <input v-model="splitSaleOption"
               type="checkbox"
               class="input-field ml-8 w-full h-4 w-4 mt-1 rounded border-gray-300 text-tb-blue focus:ring-tb-blue">
      </dd>
    </div>
    <!-- END: End show option -->
    <!-- BEGIN: Type -->
    <div v-if="products.find(i => i.id === formData.products)?.track_assisted_sales == 1 && splitSaleOption == true"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Sale Split With
        <el-tooltip placement="right">
          <template #content>
            <p class="text-sm">Selling agent you are sharing the sale with</p>
          </template>
          <QuestionMarkCircleIcon @click.prevent="true"
                                  class="p-1 ml-3 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
        </el-tooltip>
      </dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.assist_user_id }">
        <select v-model="formData.assist_user_id"
                data-placeholder="Select source"
                class="tom-select input-field ml-2 w-full"
                id="assist_user_id"
                name="assist_user_id">
          <option value="null">Select</option>
          <option v-for="agent in agents"
                  :key="agent.id"
                  :value="agent.user_id">{{ agent.user.first_name }} {{ agent.user.last_name }}</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.assist_user_id">{{ formData.errors.assist_user_id }}</div>
      </dd>
    </div>
    <!-- END: Type -->
    <!-- BEGIN: Type -->
    <div v-if="products.find(i => i.id === formData.products)?.track_assisted_sales == 1 && splitSaleOption == true"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Sale Split With
        <el-tooltip placement="right">
          <template #content>
            <p class="text-sm">Percent of sale for assisting agent</p>
          </template>
          <QuestionMarkCircleIcon @click.prevent="true"
                                  class="p-1 ml-3 h-8 w-8 cursor-pointer"></QuestionMarkCircleIcon>
        </el-tooltip>
      </dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.assist_rate }">
        <select v-model="formData.assist_rate"
                data-placeholder="Select source"
                class="tom-select input-field ml-2 w-full"
                id="assist_rate"
                name="assist_rate">
          <option v-for="split in splitPercentages"
                  :key="split.id"
                  :value="split">{{ split }}%</option>
        </select>
        <div class="error-message mt-1"
             v-if="formData.errors.assist_rate">{{ formData.errors.assist_rate }}</div>
      </dd>
    </div>
    <!-- END: Type -->
    <!-- BEGIN: Beneficiaries -->
    <div v-if="true"
         class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm mt-1 font-medium text-gray-600">Beneficiaries
      </dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.beneficiaries }">
        <div class="ml-2">
          {{ formData.beneficiaries.length }} <button type="button" class="btn btn-primary ml-4" @click.prevent="showListBeneficiariesModal"><EyeIcon class="w-4 h-4" /></button>
        </div>
        <div class="error-message mt-1"
             v-if="formData.errors.beneficiaries">{{ formData.errors.beneficiaries }}</div>
      </dd>
    </div>
    <!-- END: Beneficiaries -->
    <!-- BEGIN: Name -->
    <div class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm font-medium mt-1 text-gray-600">Notes</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.notes }">
        <textarea v-model="formData.notes"
                  id="notes"
                  type="text"
                  name="notes"
                  rows="3"
                  class=" input-field w-full ml-2"></textarea>
        <div class="error-message mt-1"
             v-if="formData.errors.notes">{{ formData.errors.notes }}</div>
      </dd>
    </div>
    <!-- END: Name -->

    <!-- BEGIN: Current Plan Info -->
    <div v-if="lead.has_type_marked_as_medicare" class="py-4 sm:py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
      <dt class="block text-sm font-medium mt-1 text-gray-600">Current Plan Info</dt>
      <dd class="mt-1 ml-2 text-sm text-gray-900 sm:mt-0 sm:col-span-2"
          :class="{ 'has-error': formData.errors.current_plan_info }">
        <input v-model="formData.current_plan_info"
            id="current_plan_info"
            type="text"
            name="current_plan_info"
            class=" input-field w-full ml-2" />
        <div class="error-message mt-1"
            v-if="formData.errors.current_plan_info">{{ formData.errors.current_plan_info }}</div>
      </dd>
    </div>
    <!-- END: Current Plan Info -->
    <div class="flex justify-center mt-6 items-center">
  <!-- Renew Policy Option -->
      <label class="text-sm whitespace-nowrap font-medium text-gray-600">
        This policy is being renewed
      </label>
      <input v-model="formData.renew" 
            type="checkbox" 
            class="input-field h-4 w-4 rounded border-gray-300 ml-2 text-tb-blue focus:ring-tb-blue">
    </div>

    <div v-if="policy?.status == 'A'" class="flex justify-center mt-3 items-center">
      <!-- Cancel Policy Option -->
      <label class="text-sm whitespace-nowrap font-medium text-gray-600">
        Cancel this policy
      </label>
      <input v-model="formData.isCancelled" 
            type="checkbox" 
            class="input-field h-4 w-4 rounded border-gray-300 ml-2 text-tb-blue focus:ring-tb-blue">
    </div>

    <!-- END: End show option -->
    <div class="text-right mt-5 mb-2">
      <button type="submit"
              class="btn btn-primary w-25"
              :class="{
                'pointer-events-none': formData.loading,
                'bg-gray-300 hover:bg-gray-400': !canSubmitTransaction,
              }"
              :disabled="!canSubmitTransaction"
        >
        <LoadingSpinner v-if="formData.loading"></LoadingSpinner>
        <span v-else>Update</span>
      </button>
    </div>
  </form>

  <Modal :show="showingListBeneficiariesModal" @onClose="hideListBeneficiariesModal" title="Beneficiaries" max-width="max-w-[900px]">
    <BeneficiariesList :beneficiaries="formData.beneficiaries"
                       @edit-beneficiary="editBeneficiary"
                       @delete-beneficiary="deleteBeneficiary"
                       @close-pane="hideListBeneficiariesModal"
                       @show-add="showFormBeneficiariesModal"
    ></BeneficiariesList>
  </Modal>

  <Modal :show="showingFormBeneficiariesModal" @onClose="hideFormBeneficiariesModal" title="Beneficiaries">
    <BeneficiariesForm
      @submit-beneficiary="processBeneficiary"
      @close-pane="hideFormBeneficiariesModal"
      :editing-beneficiary-index="editingBeneficiary.index"
      :editing-beneficiary-data="editingBeneficiary.data"
    />
  </Modal>

  <Modal :show="showLeadScheduleChoicesModal" title="Please select what you want to complete" max-width="sm:max-w-2xl" @onClose="hideLeadScheduleChoicesModal">
    <form>
      <div v-if="leadSchedulesOwnedByUser.length">
        <div class="mt-4">
          <!-- Need to split actions (that follow schedule or standalone) and appointment / tasks -->
          <div v-for="leadSchedule in leadActionsThatFollowScheduleOwnedByUser" :key="leadSchedule.id" class="flex items-center cursor-pointer">
            <input type="radio" :id="`completing_ls_${leadSchedule.id}`" class="cursor-pointer h-4 w-4 border-gray-300 text-tb-blue focus:ring-tb-blue" v-model="formData.selectedScheduleToComplete" name="`completing_lead_schedule" :value="leadSchedule.id">
            <label class="ml-2 cursor-pointer" :for="`completing_ls_${leadSchedule.id}`">
              <span v-if="leadSchedule.action.is_task">Task:</span> {{ leadSchedule.action.name }} - {{ moment(leadSchedule.agent_scheduled_datetime_literal).format('M/D/YYYY h:mm A') }}
            </label>
          </div>
        </div>

        <!-- Appointment / Tasks - can complete multiple at the same time -->
        <div v-if="leadAppointmentAndTasksOwnedByUser.length">
          <div class="mt-4 mb-2 border-b text-sm">Appointments and tasks (You can complete multiple at once):</div>
          <div v-for="leadSchedule in leadAppointmentAndTasksOwnedByUser" :key="leadSchedule.id" class="flex items-center cursor-pointer">
            <input type="checkbox" :id="`completing_ls_multiple_${leadSchedule.id}`" class="cursor-pointer input-checkbox" v-model="formData.selectedApptsAndTasksToComplete" name="`completing_lead_schedule" :value="leadSchedule.id">
            <label class="ml-2 cursor-pointer" :for="`completing_ls_multiple_${leadSchedule.id}`">
              <span v-if="leadSchedule.action.is_task">Task:</span> {{ leadSchedule.action.name }} - {{ moment(leadSchedule.agent_scheduled_datetime_literal).format('M/D/YYYY h:mm A') }}
            </label>
          </div>
        </div>

        <button :disabled="submittingCompleteSchedules" :class="[{'opacity-50 pointer-events-none':submittingCompleteSchedules}, 'btn-primary mt-4']" type="button" @click.prevent="emit('onSubmit')">Select</button>
      </div>
      <div v-else class="mt-4 text-center font-bold text-lg">
        You have no created actions for this lead.
      </div>
    </form>
  </Modal>
</template>