<template>
  <!-- Step progress -->
  <section class="col-span-12 w-full">
    <div class="flex items-center justify-center space-x-3 md:space-x-10">
      <span
        v-for="(step, i) in steps"
        :key="step"
        :class="step === currentStep ? 'bg-tb-blue text-white' : 'bg-gray-200'"
        class="flex h-8 w-8 items-center justify-center rounded-full  text-xs md:h-12 md:w-12 md:text-base">
        {{ step }}
      </span>
    </div>
  </section>

  <div v-if="currentStep > 1" class="mt-10 space-y-2 md:flex md:items-center md:justify-center md:space-x-2 md:space-y-0 col-span-12">
    <button class="btn btn-primary flex w-full items-center justify-center rounded-md px-6 py-2 md:w-auto" @click="goBack">Back</button>
    <button class="btn btn-primary flex w-full items-center justify-center rounded-md px-6 py-2 md:w-auto" v-if="currentStep < 3" @click="nextStep">Next</button>
    <button v-if="currentStep === 3 && canSubmitMainForm" :disabled="loading" class="btn btn-primary md:w-auto" @click="createSchedule">
      <LoadingSpinner v-if="loading"></LoadingSpinner>
      <span v-else>Create</span>
    </button>
  </div>

  <!-- Step 1 -->
  <div v-show="currentStep === 1" class="col-span-12 w-full">
    <section class="mt-10 text-center text-3xl font-light">First off, we need to know the action schedule name</section>
    <section class="mt-10 flex items-center justify-center">
      <div class="w-[400px]">
        <input required v-model="formData.action_schedule_name" id="crud-form-1" type="text" class="input-field w-full" placeholder="Action schedule name">

        <div v-if="user_is_admin" class="flex items-center justify-start space-x-2 my-4">
          <input v-model="formData.is_agency" type="checkbox" class="input-checkbox block" name="">
          <span>Is this agency schedule?</span>
        </div>

        <div v-if="user_is_admin" class="flex items-center justify-start space-x-2 my-4">
          <div>
            <label class="text-base font-medium text-gray-900">Working Hours</label>
            <p class="text-sm leading-5 text-gray-500">Should this schedule should use Agency Hours or Agent Working Hours?</p>
            <fieldset class="mt-4">
              <legend class="sr-only">Working Hours</legend>
              <div class="space-y-4">
                <div v-for="option in workingHoursTypes" :key="option.value" class="flex items-center">
                  <input v-model="formData.working_hours_type" :id="option.value" name="working_hours_type" type="radio" :value="option.value" class="h-4 w-4 border-gray-300 text-tb-blue focus:ring-tb-blue" />
                  <label :for="option.value" class="ml-3 block text-sm font-medium text-gray-700">{{ option.label }}</label>
                </div>
              </div>
            </fieldset>
          </div>
        </div>

        <button @click="nextStep()" :class="[['', null].includes(formData.action_schedule_name) ? 'pointer-events-none opacity-50' : '', 'mt-2 w-full rounded-md btn btn-primary px-4']" :disabled="['', null].includes(formData.action_schedule_name)">Next</button>
      </div>
    </section>
  </div>

  <!-- Step 2  -->
  <div v-show="currentStep === 2" class="col-span-12 w-full">
    <section class="mt-10 text-center text-xl font-light">
      Assign action(s) for each of the statuses
    </section>

    <!-- Statuses list -->
    <section class="flex items-center justify-center w-full">
      <div class="mt-4 flex-wrap md:flex md:items-center justify-start xl:justify-center">
        <button
          v-for="(status, index) in availableStatuses"
          :key="status.id"
          @click="this.currentStatus = status"
          :class="currentStatus.id === status.id ? 'outline-offset-1 outline-tb-blue' : 'outline-slate-200'"
          class="outline flex w-full items-center focus:outline-tb-blue justify-between space-x-2 rounded-md bg-white px-4 py-2 md:w-auto whitespace-nowrap md:mr-4 mt-4">
          <span>{{ status.name }}</span>
        </button>
      </div>
    </section>


    <section class="mt-10">
       <div class="">
        <ActionScheduleInfoBanners></ActionScheduleInfoBanners>
       </div>
      <div class="w-full rounded-md border border-slate-300 px-6 bg-white">

        <action-schedule-status-tab
          v-for="(tabData, index) in formData.statuses_actions"
          :key="`${tabData.status_id}_tab_data`"
          :current-status="currentStatus"
          :created-action="createdAction"
          :created-script="createdScript"
          :created-text-template="createdTextTemplate"
          :created-email-template="createdEmailTemplate"
          :data-set="tabData"
          :index="index"
          @updated-data="sync"
          :available-actions="availableActions"
          :available-email-templates="availableEmailTemplates"
          :available-text-templates="availableTextTemplates"
          :available-scripts="availableScripts"
          :user_is_admin="user_is_admin"
          @show-modal="handleShowModal"
          :lead-ownership-change-types="ownership_change_types"
          :grace-pull-types="grace_pull_types"
          :buckets="buckets"
          :agents="agents"
          :selected-working-hours-text="selectedWorkingHoursText"
        ></action-schedule-status-tab>
      </div>
    </section>
  </div>

  <!-- Step 3 -->
  <div v-show="currentStep === 3" class="col-span-12 w-full">
    <section class="mt-10 text-center text-3xl font-light">Schedule is ready for creation</section>
  </div>

  <ActionScheduleCreateActionModal
    @action-created="handleActionCreated"
    @modal-hidden="handleModalHidden"
    :user_is_admin="user_is_admin"
    :open="modals.createActionModal.show"></ActionScheduleCreateActionModal>
  <ActionScheduleCreateTextTemplateModal
    @text-template-created="handleTextTemplateCreated"
    @modal-hidden="handleModalHidden"
    :prepended_text="text_template_prepended_text"
    :appended_text="text_template_appended_text"
    :type="text_template_type"
    :user_is_admin="user_is_admin"
    :open="modals.createTextTemplateModal.show"
  ></ActionScheduleCreateTextTemplateModal>
  <ActionScheduleCreateEmailTemplateModal
    @email-template-created="handleEmailTemplateCreated"
    @modal-hidden="handleModalHidden"
    :user_is_admin="user_is_admin"
    :open="modals.createEmailTemplateModal.show"
  ></ActionScheduleCreateEmailTemplateModal>
  <ActionScheduleCreateVoicemailScriptModal
    @script-created="handleScriptCreated"
    @modal-hidden="handleModalHidden"
    :user_is_admin="user_is_admin"
    :open="modals.createVoicemailScriptModal.show"
  ></ActionScheduleCreateVoicemailScriptModal>
</template>

<script>
import Modal from "@/Components/Modal.vue";
import _ from "lodash";
import ActionScheduleCreateEmailTemplateModal from "./partials/ActionScheduleCreateEmailTemplateModal.vue";
import ActionScheduleCreateTextTemplateModal from "./partials/ActionScheduleCreateTextTemplateModal.vue";
import ActionScheduleStatusTab from "./partials/ActionScheduleStatusTab.vue";
import ActionScheduleCreateActionModal from "./partials/ActionScheduleCreateActionModal.vue";
import ActionScheduleCreateVoicemailScriptModal from "./partials/ActionScheduleCreateVoicemailScriptModal.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import ActionScheduleInfoBanners from "@/Components/partials/ActionScheduleInfoBanners.vue";
import InfoIcon from "@/Components/InfoIcon.vue";

export default {
  name: "AppActionScheduleCreateForm",

  components: {
    Modal,
    ActionScheduleStatusTab,
    ActionScheduleCreateActionModal,
    ActionScheduleCreateEmailTemplateModal,
    ActionScheduleCreateVoicemailScriptModal,
    ActionScheduleCreateTextTemplateModal,
    LoadingSpinner,
    ActionScheduleInfoBanners,
    InfoIcon
  },

  props: {
    user_is_admin: {
      type: Boolean,
      default: false,
    },
    statuses: {
      type: Array,
      default: () => []
    },
    actions: {
      type: Array,
      default: () => []
    },
    scripts: {
      type: Array,
      default: () => []
    },
    textTemplates: {
      type: Array,
      default: () => []
    },
    emailTemplates: {
      type: Array,
      default: () => []
    },
    text_template_prepended_text: {
      type: String,
      default: '',
    },
    text_template_appended_text: {
      type: String,
      default: '',
    },
    text_template_type: {
      type: Boolean,
      default: false,
    },
    working_hours_types: {
      type: Array,
      default: () => []
    },
    ownership_change_types: {
      type: Array,
      default: () => []
    },
    grace_pull_types: {
      type: Array,
      default: () => []
    },
    buckets: {
      type: Array,
      default: () => [],
    },
    agents: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      steps: [1, 2, 3],
      currentStep: 1,
      currentStatus: {
        id: this.statuses[0].id,
        name: this.statuses[0].name,
      },
      formData: {
        action_schedule_name: null,
        is_agency: false,
        working_hours_type: this.user_is_admin ? this.working_hours_types[0].value : this.working_hours_types[1].value, // Agency by default
        statuses_actions: [],
      },
      availableActions: _.cloneDeep(this.actions),
      availableStatuses: _.cloneDeep(this.statuses),
      availableTextTemplates: _.cloneDeep(this.textTemplates),
      availableEmailTemplates: _.cloneDeep(this.emailTemplates),
      availableScripts: _.cloneDeep(this.scripts),
      createdAction: null,
      createdTextTemplate: null,
      createdEmailTemplate: null,
      createdScript: null,
      loading: false,
      modals: {
        createActionModal: { show: false },
        createTextTemplateModal: { show: false },
        createEmailTemplateModal: { show: false },
        createVoicemailScriptModal: { show: false },
      },
      workingHoursTypes: this.working_hours_types,
      maximumAllowedHourInput: 8760,
    }
  },

  beforeMount() {
    this.formData.statuses_actions = this.statuses.map(i => {
      return {
        status_id: i.id,
        name: i.name,
        status_has_no_actions: false,
        actions: []
      }
    })
  },

  computed: {
    currentStatusNoActionsCheck: {
      get() {
        return this.formData.statuses_actions[this.currentStatus.id - 1].status_has_no_actions;
      },
      set(value) {
        this.formData.statuses_actions[this.currentStatus.id - 1].status_has_no_actions = value;
      }
    },

    actionStatusesArray() {
      return this.formData.statuses_actions;
    },

    canSubmitMainForm() {
      return true;
    },

    statusesWithoutActionsExist() {
      return this.availableStatuses.filter(i => i.ready_for_submission === false).length > 0;
    },

    selectedWorkingHoursText() {
      return this.formData.working_hours_type === 'agency' ? 'This schedule uses agency working hours.' : 'This schedule uses agent working hours.';
    }
  },

  watch: {
    actionStatusesArray: {
      handler(newValue, oldValue) {
        // Note: `newValue` will be equal to `oldValue` here
        // on nested mutations as long as the object itself
        // hasn't been replaced.
        newValue.forEach(statusTab => {
          let mapped = this.availableStatuses.map(i => i.id);
          let index = mapped.indexOf(statusTab.status_id);
          this.availableStatuses[index].ready_for_submission = (statusTab.status_has_no_actions || statusTab.actions.length > 0);
        })
      },
      deep: true
    },
  },

  methods: {
    createSchedule() {
      if (this.loading) return;

      this.loading = true;

      setTimeout(() => {
        axios
          .post(route('action-schedules.store'), {
            schedule_name: this.formData.action_schedule_name,
            is_agency: this.formData.is_agency,
            working_hours_type: this.formData.working_hours_type,
            hour_maximum: this.formData.hour_maximum,
            statuses_actions: this.formData.statuses_actions
          })
          .then((response) => {
            this.$inertia.get('/action-schedules');
            $notify.success({
              message: 'Action Schedule Created',
              showClose: true,
            })
          })
          .catch((error) => {
            console.log(error, 'AppActionScheduleCreateForm @ createSchedule error')
            $notify.error({
              title: 'Error',
              message: error.response.data.error_message ?? 'Something went wrong',
              showClose: true,
            })
          })
          .finally(() => {
            this.loading = false;
          })
      }, 1000)
    },

    nextStep() {
      if (this.currentStep === 1 && this.formData.action_schedule_name === null) {
        return;
      }

      if (this.currentStep === 1 && this.formData.action_schedule_name !== null) {
        return this.currentStep = 2;
      }

      if (this.currentStep === 2) {
        return this.currentStep = 3;
      }
    },

    goBack() {
      if (this.currentStep === 1) return;

      return this.currentStep = this.currentStep - 1;
    },

    handleActionCreated(payload) {
      this.availableActions.push(payload.action);

      this.$nextTick(() => {
        this.createdAction = payload.action;
      })
    },

    handleTextTemplateCreated(payload) {
      this.availableTextTemplates.push(payload.template);

      this.$nextTick(() => {
        this.createdTextTemplate = payload.template;
      })
    },

    handleEmailTemplateCreated(payload) {
      this.availableEmailTemplates.push(payload.template);

      this.$nextTick(() => {
        this.createdEmailTemplate = payload.template;
      })
    },

    handleScriptCreated(payload) {
      this.availableScripts.push(payload.script);

      this.$nextTick(() => {
        this.createdScript = payload.script;
      })
    },

    sync(payload) {
      this.formData.statuses_actions[payload.index] = payload.data;

      this.formData.statuses_actions[payload.index]?.actions?.forEach((item, index) => {
        item.step = index + 1;
      });
    },

    handleShowModal(modal) {
      this.modals[modal].show = true;
    },

    handleModalHidden(modal) {
      this.modals[modal].show = false;
    },
  },
}
</script>

<style scoped>

</style>
