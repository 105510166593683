<template>
  <!-- BEGIN: Lead View -->
  <div class="col-span-12">
    <div class="grid grid-cols-12 gap-6">
      <!--  Begin: Header -->
      <div class="col-span-12 md:flex space-x-4 items-center justify-between">
        <div class="flex space-x-4 items-center">
          <button type="button" class="btn btn-sm btn-primary p-0" @click.prevent="toggleEditMode('first_name', false)">
            <svg width="28" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="28" height="28" rx="7" fill="#899CC1"/>
              <path v-if="editMode"
                    d="m15.416 14.004 4.3-4.29a1.004 1.004 0 1 0-1.42-1.42l-4.29 4.3-4.29-4.3a1.004 1.004 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 .325 1.639 1 1 0 0 0 1.095-.219l4.29-4.3 4.29 4.3a1.002 1.002 0 0 0 1.639-.325 1 1 0 0 0-.22-1.095l-4.3-4.29Z"
                    fill="#fff"/>
              <path v-else
                    d="m16.505 10.249-7.97 7.97-.522 1.778 1.742-.503 7.997-7.997-1.247-1.248Zm1.36-1.36 1.247 1.247 1.162-1.162a.44.44 0 0 0 0-.624l-.624-.623a.44.44 0 0 0-.624 0l-1.16 1.162h-.001Zm3.033-2.41.623.624a2.205 2.205 0 0 1 0 3.118l-10.84 10.84-3.721 1.076a.88.88 0 0 1-1.091-1.094l1.1-3.752L17.781 6.479a2.205 2.205 0 0 1 3.118 0h-.001Z"
                    fill="#fff"/>
            </svg>
          </button>
          <h4 class="text-3xl font-semibold">
            Viewing Lead - <span class="text-3xl font-semibold">{{ lead.display_name }} ({{ lead.id }})
            <span v-if="usePage().props.value.auth?.user?.id === 306 && lead.legacy_id">(<a :href="`https://topbrokercrm.com/lead.asp?leadid=${lead.legacy_id}`" target="_blank" class="text-blue-600">{{ lead.legacy_id }}</a>)</span>
            </span>
          </h4>
        </div>

        <div class="block md:flex space-y-2 mt-2 md:mt-0 md:space-y-0 md:space-x-2 ">
          <button @click="createLeadNoteModalVisible = true" type="button" class="w-full md:w-auto btn-primary inline-flex items-center px-5 py-2 text-xl font-black">
            <PlusCircleIcon class="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
            Add Note
          </button>
          <button v-if="isAdmin" @click.prevent="markLeadAsDNC" type="button" class="w-full md:w-auto btn-danger text-xl font-black">DNC</button>
          <button v-if="isAdmin" @click.prevent="confirmDelete" type="button" class="w-full md:w-auto btn-danger text-xl font-black">Delete</button>
        </div>
      </div>

      <div class="bg-red-400 border border-red-400 text-red-700 px-4 py-3 rounded relative col-span-12" role="alert" v-if="data_hst_age_locked">
        <p>
          <strong class="font-bold">Lead is HST Aged Locked</strong>
        </p>
        <ul class="list-disc px-8">
          <li>Email addresses and phone numbers have been removed</li>
          <li>All open actions have been closed</li>
        </ul>
        <p>
          <span class="block sm:inline"></span>
        </p>
      </div>
      <!-- End: Header -->

      <!-- Tabs -->
      <div class="col-span-12 flex items-center justify-between">
        <div class="lg:hidden w-full">
          <label for="tabs" class="sr-only">Select a tab</label>
          <!-- Use an "onChange" listener to redirect the user to the selected tab URL. -->
          <select @change="setCurrentTab($event.target.value)" id="tabs" name="tabs"
                  class="block w-full rounded-md border-gray-300 focus:border-tb-blue focus:ring-tb-blue">
            <option v-for="tab in visibleTabs" :key="tab.key" :value="tab.key" :selected="currentTab === tab.key">{{
                tab.label
              }}
            </option>
          </select>
        </div>
        <div class="hidden lg:block w-full">
          <nav class="flex space-x-1 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg p-2 bg-white" aria-label="Tabs">
            <a @click.prevent="setCurrentTab(tab.key)" v-for="tab in visibleTabs" :key="tab.key" href="javascript:;"
               :class="[currentTab === tab.key ? 'bg-tb-blue text-white shadow' : 'text-gray-500 hover:text-gray-700', 'px-6 py-2 font-semibold rounded-md']"
               :aria-current="currentTab === tab.key ? 'page' : undefined">{{ tab.label }}</a>
          </nav>
        </div>
      </div>
    </div>
  </div>

  <div class="col-span-12 xl:col-span-8">
    <div class="grid grid-cols-12 gap-6">
      <div class="col-span-12 rounded-lg">
        <div class="tab-content min-h-[300px]">
          <div v-if="currentTab === tabs[0].key" :id="tabs[0].key" role="tabpanel" :aria-labelledby="tabs[0].key">
            <lead-view-info
              ref="lead-view-info-tab"
              v-model:lead="lead"
              :states="states"
              :edit-mode="editMode"
              :lead-dependants="dependants"
              :clicktocall="clicktocall"
              @show-edit-mode="handleShowEditMode"
              @close-edit-mode="toggleEditMode"
              @show-compose-sms-form="showComposeSmsForm"
              @show-compose-email-form="showComposeEmailForm"
              @refresh-dependants="handleRefreshDependants"
              :hst_age_locked="data_hst_age_locked"
              :lead-parent-options="leadParentOptions"
            >
            </lead-view-info>

            <div>
              <div class="px-2 mt-2">
                <LeadDependantsTable
                  :lead-dependants="dependants"
                  :show-details-button="lead.has_type_marked_as_group"
                  @show-edit-form="showDependantEditForm"
                  @show-create-form="dependantModalVisible = true"
                ></LeadDependantsTable>
              </div>
            </div>
          </div>
          <div v-else-if="currentTab === tabs[1].key" :id="tabs[1].key" class="tab-pane leading-relaxed"
               role="tabpanel" :aria-labelledby="tabs[1].key">
              <div class="mb-2 text-right">
                  <ElDropdown trigger="hover" :hide-on-click="false">
                      <ElButton color="#899CC1">
                          <span class="text-white">Show: {{ filterActionsFilteringString }}</span> <ArrowDownIcon class="text-white pl-2 w-4" />
                      </ElButton>
                      <template #dropdown>
                          <ElDropdownMenu>
                              <div id="filtersDropdown">
                                  <ElDropdownItem @click="filterActionsShowEverything">Show All</ElDropdownItem>
                                  <ElDropdownItem><ElCheckbox v-model="filterActions.texts.value" label="Texts" /></ElDropdownItem>
                                  <ElDropdownItem><ElCheckbox v-model="filterActions.emails.value" label="Emails" /></ElDropdownItem>
                                  <ElDropdownItem><ElCheckbox v-model="filterActions.calls.value" label="Calls" /></ElDropdownItem>
                                  <ElDropdownItem><ElCheckbox v-model="filterActions.notes.value" label="Notes" /></ElDropdownItem>
                                  <ElDropdownItem><ElCheckbox v-model="filterActions.other.value" label="Other" /></ElDropdownItem>
                              </div>
                          </ElDropdownMenu>
                      </template>
                  </ElDropdown>
              </div>
            <div class="overflow-x-auto rounded-lg border bg-tb-blue">
              <h1 class="px-2 py-2 text-white text-md font-bold">Incomplete Actions</h1>
              <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                <thead class="bg-slate-200">
                <tr>
                  <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900">Date/Time Scheduled</th>
                    <th scope="col" class="hitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Act</th>
                    <th scope="col" class="hitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Note</th>
                    <th scope="col" class="hitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Flag Action</th>
                </tr>
                </thead>
                <tbody class="divide-y divide-gray-200 bg-white">
                <tr v-if="nextLeadScheduleList.length == 0"><td colspan="5" class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900 text-center">No Action</td></tr>
                <tr v-else v-for="nextLeadSchedule in nextLeadScheduleList" :key="nextLeadScheduleList.id" :class="{
                  'bg-yellow-200': nextLeadSchedule.flagged
                }">
                    <td class="whitespace-nowrap px-2 py-3.5 text-xs text-gray-900 cursor-[help]" style="vertical-align: top;">
                      <el-tooltip placement="top">
                        <template #content>
                          Date/Time Created: {{ this.moment_wrapper(nextLeadSchedule.created_at).tz('America/New_York').format('M/D/Y h:mm A') }}
                        </template>
                        {{ this.moment_wrapper(nextLeadSchedule.scheduled_datetime).tz('America/New_York').format('M/D/Y h:mm A') }}
                      </el-tooltip>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5 text-xs text-gray-900" style="vertical-align: top;">{{nextLeadSchedule.action?.is_task ? 'Task:' : null }} {{ nextLeadSchedule.action?.name }}</td>
                    <td class="break-words px-2 py-3.5  text-xs text-gray-900">
                        <p>{{ nextLeadSchedule.user_shorthand }}</p>
                        <p>{{ nextLeadSchedule.notes }}</p>
                    </td>
                    <td class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900" style="vertical-align: top;">
                        <button v-if="!nextLeadSchedule.flagged" class="btn btn-sm btn-secondary mr-2" @click="flagSchedule(nextLeadSchedule)"><FlagIcon class="w-4" /></button>
                        <button v-else class="btn btn-sm btn-secondary mr-2" @click="unflagSchedule(nextLeadSchedule)"><XIcon class="w-4" /><FlagIcon class="w-4" /></button>
                        <button v-if="isAdmin || lead.allow_delete_notes == 1" class="btn btn-xs btn-secondary" @click="deleteSchedule(nextLeadSchedule)"><TrashIcon class="w-4" /></button>
                    </td>
                </tr>
                </tbody>
              </table>
              </div>
              <div class="mt-8 overflow-x-auto rounded-lg border bg-tb-blue">
              <h1 class="px-2 py-2 text-white text-md font-bold">Completed Actions</h1>
              <table class="min-w-full divide-y divide-gray-300 border-t border-t-gray-300">
                  <thead class="bg-slate-200">
                  <tr>
                      <th scope="col" class="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-xs font-semibold text-gray-900">Date/Time Completed</th>
                      <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Act</th>
                      <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Note</th>
                      <th scope="col" class="whitespace-nowrap px-2 py-3.5 text-left text-xs font-semibold text-gray-900">Options</th>
                  </tr>
                  </thead>
                  <tbody class="divide-y divide-gray-200 bg-white">
                  <tr v-if="visibleLeadActionHistory.length" v-for="(actionHistoryItem, index) in visibleLeadActionHistory" :key="`lah_${actionHistoryItem.id}`" :class="{
                            'bg-yellow-200': actionHistoryItem.flagged
                      }">
                      <td class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900 cursor-[help]" style="vertical-align: top;">
                        <el-tooltip placement="top">
                          <template #content>
                            <p>Date/Time Created: {{ this.moment_wrapper(actionHistoryItem.completed_lead_schedule ? actionHistoryItem.completed_lead_schedule.created_at : actionHistoryItem.created_at).format('M/D/Y h:mm A') }}</p>
                          </template>
                          {{ this.moment_wrapper(actionHistoryItem.completed_at).format('M/D/Y h:mm A') }}
                        </el-tooltip>
                      </td>
                      <td class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900" style="vertical-align: top;">
                          <span v-if="actionHistoryItem.action?.is_task">Task: </span> {{ actionHistoryItem.act_name }}
                      </td>
                      <td class="px-2 py-3.5 text-xs text-gray-900 max-w-[300px] xl:max-w-[400px] overflow-auto" style="vertical-align: top;">
                          {{ actionHistoryItem.user_shorthand }} <br v-if="actionHistoryItem.user_shorthand" />
                          <div v-if="actionHistoryItem.completed_notes" class="whitespace-pre-wrap">
                              {{ actionHistoryItem.completed_notes }}
                          </div>
                          <div v-if="actionHistoryItem.to_email">
                              To: {{ actionHistoryItem.to_email }}
                          </div>
                          <div v-if="actionHistoryItem.notes_title">
                              <a class="text-blue-600 underline" v-if="actionHistoryItem.email_message_id" @click.prevent="showEmailMessageContent(actionHistoryItem.email_message_id)" href="javascript;">{{ actionHistoryItem.notes_title }}</a>
                              <a class="text-blue-600 underline" v-else-if="actionHistoryItem.email_template_id" @click.prevent="showEmailTemplateContent(actionHistoryItem.email_template_id)" href="javascript;">{{ actionHistoryItem.notes_title }}</a>
                              <span v-else>{{ actionHistoryItem.notes_title }}</span>
                          </div>
                          <div v-if="isAdmin && actionHistoryItem.grace_pull_log_id">
                            <a class="text-blue-600 underline" @click.prevent="showGracePullDetails(actionHistoryItem.grace_pull_log_id)" href="javascript;">
                              Grace Pull Details
                            </a>
                          </div>
                      </td>
                      <td class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900" style="vertical-align: top;">
                          <div v-if="!actionHistoryItem.dynamic">
                              <button v-if="!actionHistoryItem.flagged" class="btn btn-sm btn-secondary mr-2" @click="flagLeadHistoryEntry(actionHistoryItem)"><FlagIcon class="w-4" /></button>
                              <button v-else class="btn btn-sm btn-secondary mr-2" @click="unflagLeadHistoryEntry(actionHistoryItem)"><XIcon class="w-4" /><FlagIcon class="w-4" /></button>
                              <button v-if="isAdmin || lead.allow_delete_notes == 1" class="btn btn-xs btn-secondary" @click="deleteLeadHistoryEntry(actionHistoryItem)"><TrashIcon class="w-4" /></button>
                          </div>
                          <div v-else>
                              <button v-for="extraOption in actionHistoryItem.extra_options" class="btn btn-sm btn-secondary focus:ring-0 mr-2" @click="performExtraOption(extraOption)">
                                  <DuplicateIcon class="w-4 mr-2" />
                                  <span class="text-xs">{{ extraOption.label }}</span>
                              </button>
                          </div>
                      </td>
                  </tr>
                  <tr v-else>
                      <td colspan="6" class="whitespace-nowrap px-2 py-3.5  text-xs text-gray-900 text-center">No data</td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="currentTab === tabs[3].key" :id="tabs[3].key" class="tab-pane leading-relaxed px-2"
               role="tabpanel" :aria-labelledby="tabs[2].key">
               <LeadSalesTable :active-tab="currentTab" :total-premium="totalPremium" :hst_available="hst_available" :carriers-data="carriersData" :applicants="applicants" :products="products" :enrollees="enrollees" :split-percentages="splitPercentages" :agents="agents" :policies="policies" :lead-id="lead.id" :lead="lead" :next-lead-schedules-list="nextLeadScheduleList" :user="user"></LeadSalesTable>
          </div>
          <div v-else-if="currentTab === tabs[2].key" :id="tabs[2].key" class="tab-pane leading-relaxed px-2"
               role="tabpanel" :aria-labelledby="tabs[3].key">
            <lead-view-quotesheets :quotesheets="quotesheets" :lead_id="lead.id"></lead-view-quotesheets>
          </div>
          <div v-else-if="currentTab === tabs[4].key" :id="tabs[4].key" class="tab-pane leading-relaxed p-2 md:p-5 bg-white rounded-lg border"
               role="tabpanel" :aria-labelledby="tabs[4].key">
            <lead-view-files :lead-id="lead.id"></lead-view-files>
          </div>
          <div v-else-if="currentTab === tabs[5].key" :id="tabs[5].key" class="tab-pane leading-relaxed p-2 md:p-5 bg-white rounded-lg border"
               role="tabpanel" :aria-labelledby="tabs[5].key">
            <LeadLoginTable :lead="lead" :logins="logins"></LeadLoginTable>
          </div>
          <div v-else-if="currentTab === 'questionnaires'" id="questionnaires" class="tab-pane leading-relaxed px-2 md:px-2 bg-white rounded-lg border"
               role="tabpanel" aria-labelledby="questionnaires">
            <LeadQuestionnairesTable :lead="lead" :questionnaires="questionnaires"></LeadQuestionnairesTable>
          </div>

          <div v-else-if="currentTab === 'medications'" id="medications" class="px-2 tab-pane leading-relaxed"
              role="tabpanel" aria-labelledby="medications">
            <LeadMedicationsAndDoctors :lead="lead" :lead-dependants="dependants"></LeadMedicationsAndDoctors>
          </div>

          <div v-else-if="currentTab === 'lead-billing'" id="lead-billing" class="tab-pane leading-relaxed bg-white rounded-lg border"
            role="tabpanel" aria-labelledby="lead-billing">
            <LeadBillingForm :lead="lead" :lead-billing-info="leadBillingInfo" :states="states"></LeadBillingForm>
          </div>
          <div v-else-if="currentTab === 'child-leads'" id="child-leads" class="tab-pane leading-relaxed bg-white rounded-lg border"
            role="tabpanel" aria-labelledby="child-leads">
            <LeadChildLeadsTable :lead="lead" :child-leads="childLeads.data"></LeadChildLeadsTable>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!-- END: Lead View -->


  <!-- Create Dependant Modal -->
  <Modal :show="dependantModalVisible" @onClose="closeDependantFormModal" :title="`Viewing lead ${lead.display_name } (${lead.id})`">
    <p class="mt-2 text-center">{{dependantToEdit !== null ? 'Update' : 'Create '}} Dependant</p>
    <LeadDependantForm
      :lead="lead"
      :dependant-roles="dependantRoles"
      :lead_id="lead.id"
      @dependantsUpdated="handleDependantsUpdated"
      :edit-mode="dependantToEdit !== null"
      :dependant-to-edit="dependantToEdit"
      :lead-last-name="lead.last_name"
      :lead-dependants="dependants"
      :dependant-company-roles="dependantCompanyRoles"
    ></LeadDependantForm>
  </Modal>

  <!-- Create Note Modal -->
  <Modal :show="createLeadNoteModalVisible" @onClose="createLeadNoteModalVisible = false" :title="`Viewing lead ${lead.display_name } (${lead.id})`">
    <p class="mt-2 text-center">Add a note</p>
    <LeadViewNoteForm :lead-id="lead.id" @note-added="handleNoteAdded"></LeadViewNoteForm>
  </Modal>
  <!-- Action Tab Email Message Modal -->
  <Modal :show="showEmailContentModal" @onClose="closeEmailContentModal" title="Viewing Email Content">
      <div class="flex items-center justify-center pb-3 mt-10" v-if="fetchingEmailContent">
          <LoadingSpinner color-class="text-tb-blue"></LoadingSpinner>
      </div>
      <div v-else class="mt-10 max-w-fit overflow-auto">
          <p class="text-gray-700">Subject:</p>
          <p class="font-semibold">{{ this.emailContent?.subject }}</p>
          <p class="mt-4 xt-gray-7">Email Content:</p>
          <p class="" v-html="this.emailContent?.body"></p>
      </div>

  </Modal>


  <Modal :show="showGracePullDetailsModal" @onClose="closeGracePullDetails" title="Viewing Grace Pull Details">
    <div class="flex items-center justify-center pb-3 mt-10" v-if="fetchingGracePullDetails">
      <LoadingSpinner color-class="text-tb-blue"></LoadingSpinner>
    </div>
    <div v-else class="mt-10 max-w-fit overflow-auto">
      <p class="text-gray-700">Action:</p>
      <p class="font-semibold">{{ this.gracePullDetails?.lead_schedule?.action?.name }}</p>
      <p class="mt-4 xt-gray-7">Scheduled Datetime (EST):</p>
      <p class="font-semibold">{{ this.gracePullDetails?.lead_schedule?.agent_scheduled_datetime_literal }}</p>
      <p class="mt-4 xt-gray-7">Pulled Datetime (EST):</p>
      <p class="font-semibold">{{ this.gracePullDetails?.created_at_literal }}</p>
      <p class="mt-4 xt-gray-7">Grace Hours:</p>
      <p class="font-semibold">{{ this.gracePullDetails?.lead_schedule?.action_schedule_map?.grace_pull_setting?.grace_hours }}</p>
    </div>

  </Modal>
</template>

<script>
import LeadViewNoteForm from "@/Components/partials/LeadViewNoteForm.vue";
import helpers from "../helper";
import LeadSalesTable from "./partials/LeadSalesTable.vue";
import LeadLoginTable from "./partials/LeadLoginTable.vue";
import LeadViewInfo from "@/Components/partials/LeadViewInfo.vue";
import LeadViewFiles from "@/Components/partials/LeadViewFiles.vue";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import LeadDependants from "@/Components/partials/LeadDependants.vue";
import LeadDependantsTable from "@/Components/partials/LeadDependantsTable.vue";
import LeadDependantForm from "@/Components/partials/LeadDependantForm.vue";
import LeadViewQuotesheets from "@/Components/partials/LeadViewQuotesheets.vue";
import LeadQuestionnairesTable from "@/Components/partials/LeadQuestionnairesTable.vue";
import Modal from "@/Components/Modal.vue";
import {Link, usePage} from "@inertiajs/inertia-vue3";
import {PlusCircleIcon, FlagIcon, TrashIcon, XIcon, DuplicateIcon, ArrowDownIcon} from "@heroicons/vue/outline";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import {Inertia} from "@inertiajs/inertia";
import EmailTemplatePreview from "@/Components/partials/EmailTemplatePreview.vue";
import {ElCheckbox, ElDropdown, ElDropdownMenu, ElDropdownItem, ElButton, ElTooltip} from "element-plus";
import _ from "lodash";
import LeadMedicationsAndDoctors from "@/Components/partials/LeadMedicationsAndDoctors.vue";
import LeadChildLeadsTable from "@/Components/partials/LeadChildLeadsTable.vue";
import LeadBillingForm from "@/Components/partials/LeadBillingForm.vue";

export default {
  name: "AppLeadView",

  components: {
    LeadMedicationsAndDoctors,
    LeadBillingForm,
    LeadChildLeadsTable,
    LeadQuestionnairesTable,
    ElCheckbox, ElDropdown, ElDropdownMenu, ElDropdownItem, ElButton, ElTooltip,
    EmailTemplatePreview,
    LeadViewNoteForm,
    LeadViewInfo,
    LeadViewFiles,
    LeadSalesTable,
    LeadLoginTable,
    LoadingSpinner,
    LeadDependants,
    LeadDependantsTable,
    LeadDependantForm,
    LeadViewQuotesheets,
    PlusCircleIcon, FlagIcon, TrashIcon, XIcon, DuplicateIcon, ArrowDownIcon,
    Modal,
    Link,
  },

  props: {
    timezone: {
        type: String,
        default: 'America/New_York'
    },
    lead: {
      type: Object,
      required: true,
    },
    leadActionHistory: {
      type: Array,
      required: true,
    },
    nextLeadSchedule: Object,
    states: {
      type: Array,
      required: true,
    },

    leadDependants: {
      type: Array,
    },

    dependantRoles: {
      type: Array,
      required: true,
    },

    role: String,

    policies: {
      type: Object,
      required: true,
    },

    products: {
      type: Object,
      required: true,
    },

    quotesheets: {
      type: Object,
      required: true,
    },

    enrollees: {
      type: Array,
      required: true,
    },

    agents: {
      type: Array,
      required: true,
    },

    splitPercentages: {
      type: Array,
      required: true,
    },

    carriersData: {
      type: Object,
      required: true,
    },
    applicants: {
      type: Array,
      required: true,
    },
    nextLeadScheduleList: {
      type: Array,
      required: true,
    },
    clicktocall: {
      type: String,
      default: null,
    },
    logins: {
      type: Object,
      default: true,
    },
    totalPremium: {
      type: Number,
    },
    hst_available: {
      type: Boolean,
      required: true,
    },
    user: {
      type: Object,
      required: true,
    },
    questionnaires: {
      type: Object,
    },
    leadTypeQuestionnaires: {
      type: Object,
    },
    hst_age_locked: Boolean,
    childLeads: {},
    leadParentOptions: {},
    leadBillingInfo: {},
    dependantCompanyRoles: {},
  },
  emits: ['hstAgeLockedChanged'],
  data() {
    return {
      tabs: [
        {key: 'lead-info', label: 'Lead Info'},
        {key: 'action-history', label: 'Action History'},
        {key: 'quotesheets', label: 'Quotesheets'},
        {key: 'policies', label: 'Policies'},
        {key: 'files', label: 'Files'},
        {key: 'login', label: 'Logins'},
        {
        key: 'questionnaires',
        label: 'Questionnaires',
        when: () => {
          return this.questionnaires?.data?.some(questionnaire => questionnaire.user_id === this.lead.user_id);
          }
        },
        {key: 'medications', label: 'Medical Info'},
        {
        key: 'lead-billing',
        label: 'Billing',
        when: () => {
          return this.lead.has_type_marked_as_group;
          }
        },
        {
        key: 'child-leads',
        label: `Child Leads (${this.childLeads?.data?.length ?? 0})`,
        when: () => {
          return this.lead.has_type_marked_as_group;
          }
        },
      ],
      loading: false,
      editMode: false,
      currentTab: 'lead-info',
      dependantModalVisible: false,
      createLeadNoteModalVisible: false,
      dependantToEdit: null,
      showEmailContentModal: false,
      fetchingEmailContent: false,
      emailContent: null,
      showGracePullDetailsModal: false,
      fetchingGracePullDetails: false,
      gracePullDetails: null,
      filterActions: {
          texts:  {
              value: true,
              label: 'Texts',
          },
          emails: {
              value: true,
              label: 'Emails',
          },
          calls:  {
              value: true,
              label: 'Calls',
          },
          notes:  {
              value: true,
              label: 'Notes',
          },
          other:  {
              value: true,
              label: 'Other',
          },
      },
      dependants: _.cloneDeep((this.leadDependants)),
      data_hst_age_locked: this.hst_age_locked,
    }
  },
  mounted() {
    this.checkLockForHstLeads();
  },
  methods: {
    usePage,
    moment_wrapper: moment,
    checkLockForHstLeads() {
      if (!this.data_hst_age_locked && this.lead?.hst_lead_id) {
        axios.get(route('leads.hst-lock-check', {lead: this.lead.id}))
          .then(r => {
            if (r.data?.hst_age_locked && r.data.hst_age_locked === true) {
              this.data_hst_age_locked = true;
              this.lead.phone = 'HST Aged Locked';
              this.lead.phone_2 = 'HST Aged Locked';
              this.lead.email = 'HST Aged Locked';
              this.lead.email_2 = 'HST Aged Locked';
              $notify({
                type: 'info',
                message: 'Lead is HST Age Locked',
              });
              this.$emit('hstAgeLockedChanged', this.data_hst_age_locked);
            }
          }).catch(e => {
            throw e;
        });
      }
    },
    handleShowEditMode(payload) {
      if (payload.fieldId) {
        this.toggleEditMode(payload.fieldId, payload.focusDependants)
      } else {
        this.toggleEditMode();
      }
    },

    markLeadAsDNC() {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, mark as DNC!'
      }).then((result) => {
        if (result.isConfirmed) {
          Inertia.post(route('leads.dnc', {lead: this.lead.id}), {}, {
            preserveScroll: true,
            onSuccess: (response) => {
              $notify.success({
                title: 'Success',
                message: 'Lead has been marked as DNC',
              });
              this.lead = response.props.lead;  // Update the lead with the response data
              this.$emit('leadStatusUpdated', {is_dnc: true});
            },
            onError: () => {
              $notify.error({
                title: 'Error',
                message: 'An error occurred while marking lead as DNC',
              });
            }
          });
        }
      });
    },

    toggleEditMode(scrollToFieldId = 'first_name', focusDependants = false) {
      this.editMode = !this.editMode;

      if (this.editMode) {
        this.$nextTick(() => {
          if (focusDependants) {
            document.getElementById(scrollToFieldId).scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "nearest"
            });
          } else {
            setTimeout(() => {
              document.getElementById(scrollToFieldId)?.focus();
            }, 50)
          }
        });
      }
    },

    setCurrentTab(tabKey) {
      this.currentTab = tabKey;
    },

    confirmDelete() {
      this.loading = true;
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to undo this.',
        icon: 'warning',
        confirmButtonText: "Yes, I'm sure.",
        showCancelButton: true,
        confirmButtonColor: '#B91C1C',
        cancelButtonColor: '#899CC1'
      }).then((result) => {
        if (result.isConfirmed) {
          this.deleteLead();
        }
      })
    },

    deleteLead() {
      axios.delete(`/leads/${this.lead.id}`)
      .then((response) => {
        if (response && response.data && response.data.redirect_url) {
          this.$inertia.get(response.data.redirect_url);
        }
      })
      .catch((error) => {
        Swal.fire({
          text: 'Unknown error occurred, please try again.',
          icon: 'error'
        });

        console.log("AppLeadView - handleErroredSubmission", error.response);
      })
      .finally(() => {
        this.loading = false;
      });
    },

    submitLeadInfoForm() {
      this.$refs['lead-view-info-tab'].submit();
    },

    handleDependantsUpdated(event) {
      this.dependantToEdit = null
      this.dependantModalVisible = false;
      this.dependants = this.leadDependants
    },

    showDependantEditForm(payload) {
      this.dependantToEdit = payload.dependant;

      setTimeout(() => {
        this.dependantModalVisible = true;
      }, 50)
    },

    closeDependantFormModal() {
      this.dependantModalVisible = false
      this.dependantToEdit = null;
    },

    handleNoteAdded() {
      this.createLeadNoteModalVisible = false;
    },

    flagSchedule(leadSchedule) {
        Inertia.put(route('lead-action.schedule.flag', leadSchedule.id), {}, {
            preserveScroll: true,
            onSuccess: () => {
                handlActionFlaggedSuccess();
            }
        })
    },

    flagLeadHistoryEntry(leadHistoryEntry) {
        Inertia.put(route('lead-action.history.flag', leadHistoryEntry.id), {}, {
            preserveScroll: true,
            onSuccess: () => {
                handlActionFlaggedSuccess();
            },
            onError: (e) => {
              throw e;
            }
        })
    },
    deleteLeadHistoryEntry(leadHistoryEntry) {
        Swal.fire({
            title: 'Delete this lead history entry?',
            text: 'This action cannot be undone.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#B91C1C',
            cancelButtonColor: '#899CC1'
        }).then((result) => {
            if (result.isConfirmed) {
                Inertia.delete(route('lead-action.history.delete', leadHistoryEntry.id), {
                  preserveScroll: true,
                  onSuccess: () => {
                    handleActionHistoryNoteDeletedSuccess();
                  },
                  onError: (e) => {
                      throw e;
                  }
                })
            }
        });
    },
    deleteSchedule(leadScheduule) {
      Swal.fire({
          title: 'Delete this lead\'s scheduled action?',
          text: 'This action cannot be undone.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#B91C1C',
          cancelButtonColor: '#899CC1'
      }).then((result) => {
          if (result.isConfirmed) {
              Inertia.delete(route('lead-action.schedule.delete', leadScheduule.id), {
                  preserveScroll: true,
                  onSuccess: () => {
                      handleActionHistoryNoteDeletedSuccess();
                  },
                  onError: (e) => {
                      throw e;
                  }
              })
          }
      });
    },
    unflagSchedule(leadSchedule) {
        Inertia.put(route('lead-action.schedule.unflag', leadSchedule.id), {}, {
            preserveScroll: true,
            onSuccess: () => {
                handlActionUnflaggedSuccess();
            },
            onError: (e) => {
                throw e;
            }
        })
    },
    unflagLeadHistoryEntry(leadHistoryEntry) {
        Inertia.put(route('lead-action.history.unflag', leadHistoryEntry.id), {}, {
            preserveScroll: true,
            onSuccess: () => {
                handlActionUnflaggedSuccess();
            },
            onError: (e) => {
                throw e;
            }
        })
    },
    showComposeSmsForm(payload) {
      Inertia.get(route('leads.custom-sms.compose', payload.leadId), {
        phone: payload.phone
      });
    },
    showComposeEmailForm(payload) {
      Inertia.get(route('leads.custom-email.compose', payload.leadId), {
        email: payload.email
      });
    },
    showEmailMessageContent(emailMessageId) {
      if (this.fetchingEmailContent) return;
      this.showEmailContentModal = true;
      this.fetchingEmailContent = true;
      axios
          .get(route('email-messages.preview', emailMessageId))
          .then((response) => {
              this.emailContent = response.data?.rendered;
          })
          .catch(error => {
          })
          .finally(() => {
              setTimeout(() => {
                  this.fetchingEmailContent = false;
              }, 300)
          })
    },
    showEmailTemplateContent(emailTemplateId) {
        if (this.fetchingEmailContent) return;
        this.showEmailContentModal = true;
        this.fetchingEmailContent = true;
        axios
            .get(route('email-templates.preview', [emailTemplateId, this.lead.id]))
            .then((response) => {
                this.emailContent = response.data?.rendered;
            })
            .catch(error => {
            })
            .finally(() => {
                setTimeout(() => {
                    this.fetchingEmailContent = false;
                }, 300)
            })
    },
    showGracePullDetails(grace_pull_log_id) {
      if (this.fetchingGracePullDetails) return;
      this.showGracePullDetailsModal = true;
      this.fetchingGracePullDetails = true;
      axios
        .get(route('grace-pull.log-details', grace_pull_log_id))
        .then(r => {
          this.gracePullDetails = r.data;
          console.log(this.gracePullDetails);
        })
        .finally(() => {
          setTimeout(() => {
            this.fetchingGracePullDetails = false;
          }, 300);
        });
    },
    closeGracePullDetails() {
      this.gracePullDetails = null;
      this.showGracePullDetailsModal = false;
    },
    closeEmailContentModal() {
        this.showEmailContentModal = false;
        setTimeout(() => {
            this.emailContent = null;
        }, 300)
    },
    performExtraOption(extraOption) {
      // Input initialization
      let optionEndpoint = extraOption.endpoint;
      let optionMethod   = extraOption.method.toUpperCase();
      let optionData     = extraOption.data;

      // Request initialization
      let requestOptions = {
          preserveScroll: true,
          onSuccess: () => {
              $notify.success({
                  title: 'Success',
                  message: extraOption.messages?.success ?? 'Action completed',
                  showClose: true,
              });
          },
          onError: e => {
              console.log(e);
              let errorMessage = 'asdasdsa';
              $notify.error({
                  title: 'Error',
                  message: extraOption.messages?.error ? extraOption.messages.error.replace(/\{e\}/, errorMessage) : errorMessage,
                  showClose: true,
              });
          },
          onFinish: () => {},
      }

      // DO Request
      switch(optionMethod) {
          case 'POST':
              Inertia.post(optionEndpoint, optionData, requestOptions);
              break;
          default:
              throw new Error("Method not implemented: " + optionMethod);
      }
    },
    filterActionsShowEverything() {
        for (let prop in this.filterActions) {
            console.log(prop);
            if (!this.filterActions.hasOwnProperty(prop)) continue;
            this.filterActions[prop].value = true;
        }
    },
    handleRefreshDependants(event) {
        this.dependants = event;
    }
  },
  computed: {
      isAdmin() {
        return ['Super Admin', 'Agency Admin'].indexOf(this.role) > -1
      },
      visibleTabs() {
        return this.tabs.filter(i => {
          return typeof i.when === 'undefined' || typeof i.when !== 'function' || i.when();
        });
      },
      visibleLeadActionHistory() {
          return this.leadActionHistory
              .filter(actionHistoryItem => {
                  return actionHistoryItem.action || actionHistoryItem.dynamic || actionHistoryItem.always_show;
              })
              .filter(actionHistoryItem => {
                  if (this.filterActions.emails.value &&
                      this.filterActions.texts.value &&
                      this.filterActions.calls.value &&
                      this.filterActions.notes.value &&
                      this.filterActions.other.value) {
                      return true;
                  }

                  let act_name = actionHistoryItem.act_name.toLowerCase();
                  if (act_name === 'note') {
                      return this.filterActions.notes.value;
                  }
                  if (act_name === 'sent text' || act_name === 'sent custom text') {
                      return this.filterActions.texts.value;
                  }
                  if (act_name === 'sent email' || act_name === 'sent custom email') {
                      return this.filterActions.emails.value;
                  }
                  if (act_name.substring(0, 4) === 'call') {
                      return this.filterActions.calls.value;
                  }
                  return this.filterActions.other.value;
              });
      },
      filterActionsFilteringString() {
          let showingEverything = true;
          let showing = [];

          for (let prop in this.filterActions) {
              if (!this.filterActions.hasOwnProperty(prop)) continue;
              if (!this.filterActions[prop].value) showingEverything = false;
              if (this.filterActions[prop].value) {
                  showing.push(this.filterActions[prop].label);
              }
          }

          if (showingEverything) {
              return 'All';
          } else {
              return showing.join(', ');
          }
      },
  }
}

let handlActionFlaggedSuccess = function() {
    $notify.success({
        title: 'Success',
        message: 'Action has been flagged',
        showClose: true,
    });
}
let handlActionUnflaggedSuccess = function() {
    $notify.success({
        title: 'Success',
        message: 'Action unflagged',
        showClose: true,
    });
}

let handleActionHistoryNoteDeletedSuccess = function() {
    $notify.success({
        title: 'Success',
        message: 'Action deleted',
        showClose: true,
    });
}
</script>

<style scoped lang="scss">
li.active {
  width: unset !important;
}
</style>
<style lang="scss">
#filtersDropdown {
    .el-checkbox__label {
        color: rgb(17, 24, 39);
    }
    .el-checkbox__input {
        &.is-checked {
            .el-checkbox__inner {
                background-color: #899CC1;
            }
        }
    }
}
</style>
