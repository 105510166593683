<template>
  <div v-show="editMode">
    <form @submit.prevent="submitForm" class="p-5" method="POST">
        <!-- BEGIN: Email To -->
      <div class="col-span-12 lg:col-span-6 mb-6" :class="{'has-error': formData.errors.disconnected_phone_noti}">
        <label for="disconnected_phone_noti" class="block text-left form-label text-s text-slate-600">Notification - Disconnected Phone</label>
          <div class="flex items-center justify-start space-x-2 flex-wrap">
            <div v-for="(email, i) in formData.disconnected_phone_noti" :key="email" class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ email }}</span>
              <button @click.prevent="removeEmail('disconnected_phone_noti', email)" class="btn text-red-500 p-0 rounded-md border-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </button>
            </div>
          </div>
          <div class="mt-2">
            <input ref="disconnected_phone_noti" id="disconnected_phone_noti" v-model="emailToAdd" placeholder="example@email.com" type="email" class="form-control form-control-sm input-field w-full" name="email_to">
            <button @click.prevent="addMore('disconnected_phone_noti')" type="button" class="btn-primary text-xs mt-2">Add</button>
          </div>
          <div v-if="formData.errors.disconnected_phone_noti" class="error-message">{{ formData.errors.disconnected_phone_noti }}</div>
      </div>
        <!-- END: Email To -->

      <!-- BEGIN: Email To -->
      <div class="col-span-12 lg:col-span-6 mb-6" :class="{'has-error': formData.errors.dead_lead_noti}">
        <label for="dead_lead_noti" class="block text-left form-label text-s text-slate-600">Notification - Dead Lead</label>
          <div class="flex items-center justify-start space-x-2 flex-wrap">
            <div v-for="(email, i) in formData.dead_lead_noti" :key="email" class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ email }}</span>
              <button @click.prevent="removeEmail('dead_lead_noti', email)" class="btn text-red-500 p-0 rounded-md border-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </button>
            </div>
          </div>
          <div class="mt-2">
            <input ref="disconnected_phone_noti" id="disconnected_phone_noti" v-model="emailToAddDead" placeholder="example@email.com" type="email" class="form-control form-control-sm input-field w-full" name="email_to">
            <button @click.prevent="addMore('dead_lead_noti')" type="button" class="btn-primary text-xs mt-2">Add</button>
          </div>
          <div v-if="formData.errors.dead_lead_noti" class="error-message">{{ formData.errors.dead_lead_noti }}</div>
      </div>
        <!-- END: Email To -->
              <!-- BEGIN: Email To -->
      <div class="col-span-12 lg:col-span-6 mb-6" :class="{'has-error': formData.errors.carrier_contract_request_noti}">
        <label for="carrier_contract_request_noti" class="block text-left form-label text-s text-slate-600">Notification - Carrier Contract Request</label>
          <div class="flex items-center justify-start space-x-2 flex-wrap">
            <div v-for="(email, i) in formData.carrier_contract_request_noti" :key="email" class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ email }}</span>
              <button @click.prevent="removeEmail('carrier_contract_request_noti', email)" class="btn text-red-500 p-0 rounded-md border-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </button>
            </div>
          </div>
          <div class="mt-2">
            <input ref="carrier_contract_request_noti" id="carrier_contract_request_noti" v-model="emailToAddCarrier" placeholder="example@email.com" type="email" class="form-control form-control-sm input-field w-full" name="email_to">
            <button @click.prevent="addMore('carrier_contract_request_noti')" type="button" class="btn-primary text-xs mt-2">Add</button>
          </div>
          <div v-if="formData.errors.carrier_contract_request_noti" class="error-message">{{ formData.errors.carrier_contract_request_noti }}</div>
      </div>
        <!-- END: Email To -->
              <!-- BEGIN: Email To -->
      <div class="col-span-12 lg:col-span-6 mb-6" :class="{'has-error': formData.errors.split_sale_noti}">
        <label for="split_sale_noti" class="block text-left form-label text-s text-slate-600">Notification - Split Sale</label>
          <div class="flex items-center justify-start space-x-2 flex-wrap">
            <div v-for="(email, i) in formData.split_sale_noti" :key="email" class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ email }}</span>
              <button @click.prevent="removeEmail('split_sale_noti', email)" class="btn text-red-500 p-0 rounded-md border-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </button>
            </div>
          </div>
          <div class="mt-2">
            <input ref="split_sale_noti" id="split_sale_noti" v-model="emailToAddSplit" placeholder="example@email.com" type="email" class="form-control form-control-sm input-field w-full" name="email_to">
            <button @click.prevent="addMore('split_sale_noti')" type="button" class="btn-primary text-xs mt-2">Add</button>
          </div>
          <div v-if="formData.errors.split_sale_noti" class="error-message">{{ formData.errors.split_sale_noti }}</div>
      </div>
        <!-- END: Email To -->
              <!-- BEGIN: Email To -->
      <div class="col-span-12 lg:col-span-6 mb-6" :class="{'has-error': formData.errors.first_agent_sale_noti}">
        <label for="first_agent_sale_noti" class="block text-left form-label text-s text-slate-600">Notification - First Agent Sale</label>
          <div class="flex items-center justify-start space-x-2 flex-wrap">
            <div v-for="(email, i) in formData.first_agent_sale_noti" :key="email" class="bg-slate-300 mt-1 pl-3 pr-2 py-0.5 rounded-md text-sm flex justify-between items-center space-x-2 mr-1">
              <span class="text-sm py-1 font-medium">{{ email }}</span>
              <button @click.prevent="removeEmail('first_agent_sale_noti', email)" class="btn text-red-500 p-0 rounded-md border-none">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" aria-hidden="true" class="w-5 h-5"><path stroke-linecap="round" stroke-linejoin="round" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              </button>
            </div>
          </div>
          <div class="mt-2">
            <input ref="first_agent_sale_noti" id="first_agent_sale_noti" v-model="emailToAddFirstAgent" placeholder="example@email.com" type="email" class="form-control form-control-sm input-field w-full" name="email_to">
            <button @click.prevent="addMore('first_agent_sale_noti')" type="button" class="btn-primary text-xs mt-2">Add</button>
          </div>
          <div v-if="formData.errors.first_agent_sale_noti" class="error-message">{{ formData.errors.first_agent_sale_noti }}</div>
      </div>
        <!-- END: Email To -->
  </form>
</div>

  <!-- Read Only Mode -->
  <!--<AgencyNotificationReadOnly v-if="!editMode" :data="formData" @show-edit-mode="handleShowEditMode"></AgencyNotificationReadOnly>-->

</template>

<script setup>
import helpers from "../../helper";
import AgencyNotificationReadOnly from "./AgencyNotificationReadOnly.vue";
import _ from "lodash";
import "@melloware/coloris/dist/coloris.css";
import LoadingSpinner from "@/Components/LoadingSpinner.vue";
import {ref, computed, onMounted} from "vue";
import { useForm } from "@inertiajs/inertia-vue3";
import axios from "axios";


const props = defineProps({
  agency_info: {
    type: Object,
    required: true,
  },
  editMode: {
    type: Boolean,
    default: false,
  },
})

function initialize() {
  formData.disconnected_phone_noti = props.agency_info.disconnected_phone_noti;
  formData.dead_lead_noti = props.agency_info.dead_lead_noti;
  formData.carrier_contract_request_noti = props.agency_info.carrier_contract_request_noti;
  formData.split_sale_noti = props.agency_info.split_sale_noti;
  formData.first_agent_sale_noti = props.agency_info.first_agent_sale_noti;
}
onMounted(() => {
  initialize();
})

const emits = defineEmits(['update:agency_info', 'show-edit-mode'])

const loading = ref(false)
const emailToAdd = ref(null)
const emailToAddDead = ref(null)
const emailToAddCarrier = ref(null);
const emailToAddSplit = ref(null);
const emailToAddFirstAgent = ref(null);
const addingEmail = ref(false)
function addMore(formDataKey) {
  // Check if formDataKey exists in formData
  if (!formData.hasOwnProperty(formDataKey)) {
    console.error(`Invalid formDataKey: ${formDataKey}`);
    return;
  }

  // Use the correct variable based on the formDataKey
  let emailValue = null;

  switch (formDataKey) {
    case 'disconnected_phone_noti':
      emailValue = emailToAdd.value;
      break;
    case 'dead_lead_noti':
      emailValue = emailToAddDead.value;
      break;
    case 'carrier_contract_request_noti':
      emailValue = emailToAddCarrier.value;
      break;
    case 'split_sale_noti':
      emailValue = emailToAddSplit.value;
      break;
    case 'first_agent_sale_noti':
      emailValue = emailToAddFirstAgent.value;
      break;
  }

  if (emailValue === null) {
    return;
  }

  // Initialize formData[formDataKey] as an empty array if it's null or undefined
  if (!Array.isArray(formData[formDataKey])) {
    formData[formDataKey] = [];
  }

  if (formData[formDataKey].includes(emailValue)) {
    // Make sure formData.errors is initialized
    formData.errors = formData.errors || {};
    formData.errors[formDataKey] = 'This email is already added.';
    return;
  }

  if (addingEmail.value) return;
  addingEmail.value = true;
  axios
    .post(route('agencies.notifications.validate-email', props.agency_info.id), {
      email: emailValue
    })
    .then((response) => {
      if (response && response.data && response.data.validatedEmail) {
        formData[formDataKey].push(response.data?.validatedEmail);

        // Reset the correct variable based on the formDataKey
        switch (formDataKey) {
          case 'disconnected_phone_noti':
            emailToAdd.value = null;
            break;
          case 'dead_lead_noti':
            emailToAddDead.value = null;
            break;
          case 'carrier_contract_request_noti':
            emailToAddCarrier.value = null;
            break;
          case 'split_sale_noti':
            emailToAddSplit.value = null;
            break;
          case 'first_agent_sale_noti':
            emailToAddFirstAgent.value = null;
            break;
        }

        // Make sure formData.errors is initialized
        formData.errors = formData.errors || {};
        formData.errors[formDataKey] = null;

        // Submit the form after successfully adding an email
        submitForm();
      }
    })
    .catch(error => {
      if (error.response && error.response.data && error.response.status === 422) {
        // Make sure formData.errors is initialized
        formData.errors = formData.errors || {};
        formData.errors[formDataKey] = error.response.data.errors.email[0] ?? null;

        nextTick(() => {
          helpers.scrollToClass('has-error');
        })
      }
    })
    .finally(() => {
      addingEmail.value = false;
    });
}
function removeEmail(formDataKey, email) {
  // Check if formDataKey exists in formData
  if (!formData.hasOwnProperty(formDataKey)) {
    console.error(`Invalid formDataKey: ${formDataKey}`);
    return;
  }

  let index = formData[formDataKey].findIndex(i => i === email);

  if (index !== -1) {
    // Remove the email from the local form data
    //formData[formDataKey].splice(index, 1);

    axios
    .post(route('agencies.notifications.delete-email', props.agency_info.id), {
      email: email,
      type: formDataKey
    })
    .then((response) => {
      console.log(response, response.data, response.data.emails)
      if (response && response.data && response.data.emails) {
        formData[formDataKey] = response.data.emails;
      }
    })
  }
}

const formData = useForm({
  disconnected_phone_noti: [],
  dead_lead_noti: [],
  carrier_contract_request_noti: [],
  split_sale_noti: [],
  first_agent_sale_noti: [],
})


function submitForm() {
  if (formData.processing) return;

  formData.post(route('agencies.notifications', props.agency_info.id), {
    preserveScroll: true,
    preserveState: true,
    only: ['agency_info'],
    onSuccess: () => {
      $notify.success({
        message: 'Success',
        showClose: true,
      })
    },
    onError: (error) => {
      nextTick(() => {
        helpers.scrollToClass('has-error');
      })
    },
    onFinish: () => {}
  })
}

</script>

<style>
.clr-field {
  width: 100%;
}

/*.ck .ck .ck-content {*/
/*  min-height: 180px !important;*/
/*  height: 180px !important;*/
/*}*/
</style>